import React from 'react';
import Modal from 'react-modal';
import { MdCancel } from "react-icons/md";
import styles from './styles.module.css';

Modal.setAppElement("#root");

function ModalBase({
    isOpen,
    onClose,
    children,
    size = "small",
    overlayClassName = "",
    modalClassName = "",
    contentClassName = ""
}){
    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onClose}
            onClose={onClose}
            className={`${styles.modal} ${styles[size]} ${modalClassName}`}
            overlayClassName={`${styles.overlay} ${overlayClassName}`}
        >  
            <>
                <MdCancel onClick={onClose} size={29} color="#b1bbcd" className="opacity modal-button-close" />
                <div className={`${styles.container} ${contentClassName}`}>
                    {children}
                </div>
            </>
        </Modal>
    );
}

export default ModalBase;