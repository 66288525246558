/* eslint-disable import/no-cycle */
import React from "react";
import { Provider } from "react-redux";
import numeral from "numeral";
import { PersistGate } from "redux-persist/lib/integration/react";
import firebase from "firebase/app";
import "firebase/messaging";
import { ToastContainer } from "react-toastify";
import rootSaga from "./ducks/sagas";
import configureStore from "./ducks";
import Routes from "./templates";
import { firebaseConfig } from "./constants";
import 'react-toastify/dist/ReactToastify.css';

numeral.register("locale", "es", {
  delimiters: {
    thousands: ".",
    decimal: ",",
  },
  abbreviations: {
    thousand: "k",
    million: "m",
    billion: "b",
    trillion: "t",
  },
  currency: {
    symbol: "$",
  },
});

numeral.locale("es");

if (!firebase.apps.length) firebase.initializeApp(firebaseConfig);

let messaging;
if (firebase.messaging.isSupported()) {
  messaging = firebase.messaging();
  messaging.usePublicVapidKey(
    "BBVLr0LEHeNM0CFPVK_1FyymUiOBLUl5T79zh6_XNjp3u8i8_HtAzzdx0ZHMXUUbtLRinGhFSe5XlC46VHBuxOM"
  );
}

const { store, persistor, sagaMiddleware } = configureStore();
sagaMiddleware.run(rootSaga);

function App() {
  return (
    <>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <Routes />
        </PersistGate>
      </Provider>
      <ToastContainer
        theme="colored"
        position="bottom-left"
        autoClose={2000}
        hideProgressBar
        closeOnClick
        pauseOnHover
        draggable
      />
    </>
  );
}

export { store };

export default App;
