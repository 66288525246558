import * as types from "./types";

export default function registerInit(payload, callback) {
  return {
    type: types.REGISTER_INIT,
    payload,
    callback,
  };
}

export function recoverPasswordInit(payload, callback) {
  return {
    type: types.RECOVER_PASSWORD_INIT,
    payload,
    callback,
  };
}

export function changePasswordInit(payload, callback) {
  return {
    type: types.CHANGE_PASSWORD_INIT,
    payload,
    callback,
  };
}

export function resetPasswordInit(payload, callback) {
  return {
    type: types.RESET_PASSWORD_INIT,
    payload,
    callback,
  };
}

export function sendSMSInit(payload, callback) {
  return {
    type: types.SEND_SMS_INIT,
    payload,
    callback,
  };
}

export function verifyPhoneInit(payload, callback) {
  return {
    type: types.VERIFY_PHONE_INIT,
    payload,
    callback,
  };
}

export function updateProfileInit(id, payload, callback) {
  return {
    type: types.UPDATE_PROFILE_INIT,
    id,
    payload,
    callback,
  };
}

export function loginInit(payload, callback) {
  return {
    type: types.LOGIN_INIT,
    payload,
    callback,
  };
}

export function googleLoginInit(payload, history) {
  return {
    type: types.GOOGLE_LOGIN_INIT,
    history,
    payload,
  };
}

export function facebookLoginInit(payload, history) {
  return {
    type: types.FACEBOOK_LOGIN_INIT,
    history,
    payload,
  };
}

export function logout(payload, token) {
  return {
    type: types.LOGOUT,
    payload,
    token,
  };
}

export function confirmEmailInit(payload, callback) {
  return {
    type: types.CONFIRM_EMAIL_INIT,
    payload,
    callback,
  };
}

export function contactInit(payload, callback) {
  return {
    type: types.CONTACT_INIT,
    payload,
    callback,
  };
}
