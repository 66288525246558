import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import Modal from "react-modal";

const Container = styled.div`
  padding: ${(props) => props.padding};
  overflow: auto;
`;

Modal.setAppElement("#root");

export default function Drawer({ isOpen, onClose, children, padding }) {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      closeTimeoutMS={300}
      contentLabel="Drawer menu"
      className="drawer"
    >
      <Container padding={padding}>{children}</Container>
    </Modal>
  );
}

Drawer.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  children: PropTypes.element.isRequired,
  padding: PropTypes.string,
};
Drawer.defaultProps = {
  padding: "16px",
};
