import numeral from "numeral";

export const getAmount = (amount) => {
  return numeral(parseInt(amount, 10) / 100);
};

export const getOrderNumber = (number) => {
  if (!number) return number;
  let orderNumber = number.toString();
  if (orderNumber.length < 5) {
    for (let i = 0; orderNumber.length <= 4; i += 1) {
      orderNumber = `0${orderNumber}`;
    }
    return orderNumber;
  }
  if (number.length === 5) {
    return number;
  }
  if (number.length > 5) {
    orderNumber = number.substr(number.length - 5, number.length - 1);
    return orderNumber;
  }
  return number;
};

export const fakePost = (path, params, method = "post") => {
  // The rest of this code assumes you are not using a library.
  // It can be made less wordy if you use one.
  const form = document.createElement("form");
  form.method = method;
  form.action = path;

  // eslint-disable-next-line no-restricted-syntax
  for (const key in params) {
    // eslint-disable-next-line no-prototype-builtins
    if (params.hasOwnProperty(key)) {
      const hiddenField = document.createElement("input");
      hiddenField.type = "hidden";
      hiddenField.name = key;
      hiddenField.value = params[key];

      form.appendChild(hiddenField);
    }
  }

  document.body.appendChild(form);
  form.submit();
};

export const customToast = () => {
  // toast(message, {
  //   position: toast.POSITION.BOTTOM_RIGHT,
  //   className: `custom-toast`,
  //   progressClassName: type || "custom-toast-progress",
  // });
};

function convertDMSToDD(degrees, minutes, seconds, direction) {
  let dd =
    parseFloat(degrees) +
    parseFloat(minutes) / 60 +
    parseFloat(seconds) / (60 * 60);

  if (direction === "S" || direction === "W") {
    dd *= -1;
  } // Don't do anything for N or E
  return dd;
}
export const parseLatLng = (lat, lng) => {
  if (typeof lat === "string" && lat.indexOf("'") !== -1) {
    const parts = `${lat} ${lng}`.split(/[^\d\w]+/);
    const latParsed = convertDMSToDD(
      parts[0],
      parts[1],
      `${parts[2]}.${parts[3]}`,
      parts[4]
    );
    const lngParsed = convertDMSToDD(
      parts[5],
      parts[6],
      `${parts[7]}.${parts[8]}`,
      parts[9]
    );
    return { lat: latParsed, lng: lngParsed };
  }
  return { lat, lng };
};

export function getDistance(lat1, lon1, lat2, lon2, unit) {
  if (lat1 === lat2 && lon1 === lon2) {
    return 0;
  }
  const radlat1 = (Math.PI * lat1) / 180;
  const radlat2 = (Math.PI * lat2) / 180;
  const theta = lon1 - lon2;
  const radtheta = (Math.PI * theta) / 180;
  let dist =
    Math.sin(radlat1) * Math.sin(radlat2) +
    Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
  if (dist > 1) {
    dist = 1;
  }
  dist = Math.acos(dist);
  dist = (dist * 180) / Math.PI;
  dist = dist * 60 * 1.1515;
  if (unit === "K") {
    dist *= 1.609344;
  }
  if (unit === "N") {
    dist *= 0.8684;
  }
  return dist;
}

export function getFormApiError({ apiErrors, fields }) {
  const errorKeys = Object.keys(apiErrors);
  if (errorKeys.length) {
    const fieldWithError = fields.find((item) => item.id === errorKeys[0]);
    // if (!fieldWithError) customToast(fieldWithError.toString());
    if (fieldWithError) {
      return {
        fieldWithError: errorKeys[0],
        error:
          typeof apiErrors[errorKeys[0]][0] === "string"
            ? apiErrors[errorKeys[0]].toString()
            : apiErrors[errorKeys[0]][0].description,
      };
    }
  }
  return { fieldWithError: null };
}

export function getApiError(apiErrors) {
  const errorKeys = Object.keys(apiErrors);
  if (errorKeys.length) {
    return {
      field: errorKeys[0],
      error:
        typeof apiErrors[errorKeys[0]][0] === "string"
          ? apiErrors[errorKeys[0]].toString()
          : apiErrors[errorKeys[0]][0].description,
    };
  }
  return { field: null };
}

export function validateEmail(email) {
  return /^\w+([.+-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,4})+$/.test(email);
}

// export function isValidUrl(url, obligatory = 1, ftp) {
//   // Si no se especifica el paramatro "obligatory", interpretamos
//   // que no es obligatorio
//   if (obligatory === undefined) obligatory = 0;
//   // Si no se especifica el parametro "ftp", interpretamos que la
//   // direccion no puede ser una direccion a un servidor ftp
//   if (ftp === undefined) ftp = 0;

//   if (url === "" && obligatory === 0) return true;
//   let pattern;
//   if (ftp) pattern = /^(http|https|ftp):\/\/[a-z0-9.-]+.[a-z]{2,4}/gi;
//   else pattern = /^(http|https):\/\/[a-z0-9.-]+.[a-z]{2,4}/gi;

//   if (url.match(pattern)) return true;
//   return false;
// }

export function justNumbers(e) {
  if (typeof e === "string") {
    return /\d/.test(e);
  }
  const keynum = e.keyCode || e.which;
  const result = /\d/.test(String.fromCharCode(keynum));
  if (!result) {
    e.preventDefault();
  }
  return result;
}

export function justLettersOrNumbers(e) {
  if (typeof e === "string") return /\d/.test(e) || /^[a-z A-Záéóíú]*$/.test(e);

  const key = e.keyCode || e.which;
  if (
    /\d/.test(String.fromCharCode(key)) ||
    /^[a-z A-Z]*$/.test(String.fromCharCode(key))
  ) {
    return true;
  }
  e.preventDefault();
  return false;
}

export function justLetters(e) {
  if (typeof e === "string") return /^[a-z A-Z]*$/.test(e);
  const key = e.keyCode || e.which;
  const result = /^[a-z A-Z]*$/.test(String.fromCharCode(key));
  if (!result) {
    e.preventDefault();
  }

  return /^[a-z A-Z]*$/.test(String.fromCharCode(key));
}

export function phoneNumber(e) {
  const result = e.target.value.match(/^\+?\d*$/);
  return result;
}

export function notSpaces(e) {
  const key = e.keyCode || e.which;
  if (key === 32) {
    return false;
  }
  return true;
}

export function notItitWithSpaces(e) {
  const key = e.keyCode || e.which;
  if (key === 32 && e.target.value.length === 0) {
    return false;
  }
  return true;
}

export function someIncludes(needles = [], haystack = []) {
  return needles.some((role) => haystack.includes(role));
}

export const ROLE_COMMERCE = 'ROLE_COMMERCE';
export const ROLE_ADMIN = 'ROLE_ADMIN';
export const ROLE_SUPER_ADMIN = 'ROLE_SUPER_ADMIN';
export const ROLE_USER = 'ROLE_USER';
export const ROLE_BRAND = 'ROLE_BRAND';
export const ROLE_WAREHOUSE_WORKER = 'ROLE_WAREHOUSE_WORKER'