import React from "react";
import { MdCancel, MdDelete } from "react-icons/md";
import styled from "styled-components";
import Modal from "react-modal";
import Button from "../Button";
import QuantityButtons from "../QuantityButtons";
import VerticalQuantityButtons from "../VerticalQuantityButtons";
import useWarehouseCartStore from "../../hooks/useWarehouseCartStore";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    padding: 0,
    marginRight: "-50%",
    borderRadius: 20,
    boxShadow: "0 20px 20px 0 rgba(0, 0, 0, 0.1)",
    border: "none",
    transform: "translate(-50%, -50%)",
    overflow: "visible",
    zIndex: 999999999999,
  },
};

const Container = styled.div`
  display: flex;
  flex-direction: column;

  padding-top: 20px;
  padding-bottom: 20px;
  position: relative;
  width: 768px;
  min-height: 400px;
  div.divider {
    min-height: 1px;
    background-color: #eaeaea;
  }
  @media (max-width: 768px) {
    padding-top: 10px;
    padding-bottom: 10px;
    max-height: calc(100vh - 80px);
    width: calc(100vw - 20px);
  }
`;
const CloseButton = styled.div`
  position: absolute;
  right: 5px;
  top: 5px;
  background-color: #fff;
  width: 26px;
  height: 26px;
  border-radius: 13px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;
const Title = styled.p`
  font-size: 16px;
  font-weight: bold;
  color: #404040;
  margin: ${(props) => props.margin};
  @media (max-width: 768px) {
    margin: ${(props) => props.marginSm};
  }
`;
const Table = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  align-items: center;
  justify-items: center;
  column-gap: 10px
`;
const Content = styled.div`
  flex: 1;
`;

const NoResults = styled.p`
  text-align: center;
  margin: 30px;
`;
const ContainerCar = styled.div`
  padding: 5px 5px;
  border-radius: 10px;
  background-color: #f0f0f0;
  margin-bottom: 10px;
`;
const ContainerBtnPayment = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-right: 10px;
  margin-bottom: 10px;
  margin-top: 15px;
`;
const ContainerScroll = styled.div`
  overflow-y: auto;
  max-height: calc(100vh - 180px);
  padding-top: 5px;
  padding: 10px;
  @media (max-width: 768px) {
    padding: 5px;
  }
`;
Modal.setAppElement("#root");

export default function WarehouseCartModal() {

  const { 
    warehouseCart,
    toggleWarehouseCartModal,
    removeWarehouseCartItem,
    incrementWarehouseCartItem,
    toggleWarehouseCartPromotorModal
  } = useWarehouseCartStore();

  const handleClose = () => {
    toggleWarehouseCartModal()
  };

  const handleClickWarehouseRequestOrderButton = () => {
    toggleWarehouseCartModal()
    toggleWarehouseCartPromotorModal()
  };
  

  return (
    <Modal
      isOpen={warehouseCart.isOpenModal}
      onRequestClose={handleClose}
      style={customStyles}
      closeTimeoutMS={300}
      contentLabel="Detalle del producto"
    >
        <Container>
          <CloseButton onClick={handleClose}>
            <MdCancel className="opacity" size={29} color="#b1bbcd" />
          </CloseButton>
          <Title marginSm="0px 0px 10px 10px" margin="0px 0px 15px 20px">
            Carrito de productos
          </Title>
          <div className="divider" />
          <Content>
            <ContainerScroll>
              {warehouseCart.items.map((productItem, productItemIndex) => {
                return (
                  <ContainerCar key={productItemIndex}>
                      <Table className="w-fuk">
                          <img
                            className="w-20 h-57 sm:w-[69px] sm:h-[57px] object-cover rounded-[8px]"
                            src={
                              productItem.image
                            }
                            alt="imagen del producto"
                          />
                          <div className="featured-text">
                            { productItem.sku }
                          </div>
                        <div className="hidden-md-and-up row-cont flex-end-row">
                          <VerticalQuantityButtons
                            onClickMore={() => incrementWarehouseCartItem(productItem, 1)}
                            onClickLess={() => incrementWarehouseCartItem(productItem, -1)}
                            quantity={productItem.quantity}
                          />
                        </div>
                        <div className="hidden-sm">
                          <QuantityButtons
                            onClickMore={() => incrementWarehouseCartItem(productItem, 1)}
                            onClickLess={() => incrementWarehouseCartItem(productItem, -1)}
                            quantity={productItem.quantity}
                          />
                        </div>
                        <MdDelete
                          onClick={() => removeWarehouseCartItem(productItem)}
                          size={26}
                          className="opacity"
                          color="#b1bbcd"
                        />
                      </Table>
                  </ContainerCar>
                );
              })}
              {
                warehouseCart.items.length === 0 && (
                  <NoResults>No tienes productos en tu carrito</NoResults>
                )
              }
            </ContainerScroll>
          </Content>
          <div className="divider" />
          <div className="row-cont space-between">
            <div>
              <Button
                width="200px"
                widthSm="140px"
                paddingSm="0px 10px"
                marginSm="10px 0px 0px 10px"
                margin="20px 0px 0px 20px"
                onClick={handleClose}
              >
                Volver
              </Button>
            </div>
            <ContainerBtnPayment>
              {/* {
                Array.isArray(cart) && cart.length ? 
              } */}
              <Button
                // outline={!cart.length === 0 ? undefined : true}
                backgroundColor={!warehouseCart.items.length ? "#a0a0a0" : ""}
                width="200px"
                widthSm="140px"
                onClick={handleClickWarehouseRequestOrderButton}
                disabled={!warehouseCart.items.length}
              >
                Solicitar a bodega
              </Button>
            </ContainerBtnPayment>
          </div>
        </Container>
    </Modal>
  );
}
