import { create } from 'zustand'

const useProductLocationUpdates = create((set) => ({
  productLocationUpdates: 0,

  incrementProductLocationUpdates: () => 
    set(state => ({
      productLocationUpdates: state.productLocationUpdates + 1
    }))
}));

export default useProductLocationUpdates;