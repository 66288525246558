import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import Header from "./Header";
import Footer from "./Footer";

const Container = styled.div`
  height: 100vh;
  width: 100%;
`;

const Children = styled.div`
  width: 100%;
  min-height: 80vh;
`;

const Layout = ({ children, canGoBack, className }) => {
  return (
    <Container className={className}>
      <Header canGoBack={canGoBack} />
      <Children>{children}</Children>
      <Footer />
    </Container>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  canGoBack: PropTypes.bool,
  className: PropTypes.string,
};

Layout.defaultProps = {
  canGoBack: false,
  className: undefined,
};

export default Layout;
