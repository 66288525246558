// import moment from "moment";
import { REHYDRATE } from "redux-persist/es/constants";
import * as types from "./types";
import {
  POST_REQUEST_CHECKOUT_INIT,
  POST_PAYMENT_INIT,
} from "../shopping/types";
import {
  UPDATE_STORE_MANAGER_COMPLETE,
  UPDATE_STORE_COMPLETE,
} from "../store/types";

const initialState = {};

export default function accessStore(state = initialState, action) {
  let incoming;
  switch (action.type) {
    case UPDATE_STORE_COMPLETE:
      return {
        ...state,
        user: {
          ...state.user,
          commerce: {
            ...action.payload,
          },
        },
      };
    case types.VERIFY_PHONE_INIT:
      return {
        ...state,
        isVerifyingPhone: true,
      };
    case types.VERIFY_PHONE_COMPLETE:
      return {
        ...state,
        isVerifyingPhone: false,
        user: {
          ...state.user,
          profile: {
            ...state.user.profile,
            phone_status: "valid",
          },
        },
      };
    case types.VERIFY_PHONE_ERROR:
      return {
        ...state,
        isVerifyingPhone: false,
      };
    case types.SEND_SMS_INIT:
      return {
        ...state,
        isSendingSMS: true,
      };
    case types.SEND_SMS_COMPLETE:
      return {
        ...state,
        isSendingSMS: false,
        // lastSMSSentTime: moment().format(),
      };
    case types.SEND_SMS_ERROR:
      return {
        ...state,
        isSendingSMS: false,
      };
    case types.CHANGE_PASSWORD_INIT:
      return {
        ...state,
        isChangingPassword: true,
      };
    case types.CHANGE_PASSWORD_COMPLETE:
      return {
        ...state,
        isChangingPassword: false,
      };
    case types.CHANGE_PASSWORD_ERROR:
      return {
        ...state,
        isChangingPassword: false,
      };
    case types.REGISTER_INIT:
      return {
        ...state,
        isRegistering: true,
      };
    case types.REGISTER_COMPLETE:
      return {
        ...state,
        isRegistering: false,
      };
    case types.REGISTER_ERROR:
      return {
        ...state,
        isRegistering: false,
      };
    case types.UPDATE_PROFILE_INIT:
      return {
        ...state,
        isUpdatingProfile: true,
      };
    case types.UPDATE_PROFILE_COMPLETE:
      return {
        ...state,
        isUpdatingProfile: false,
        ...action.payload,
      };
    case types.UPDATE_PROFILE_ERROR:
      return {
        ...state,
        isUpdatingProfile: false,
      };
    case types.CONFIRM_EMAIL_INIT:
      return {
        ...state,
        isConfirmingEmail: true,
      };
    case types.CONFIRM_EMAIL_COMPLETE:
      return {
        ...state,
        isConfirmingEmail: false,
      };
    case types.CONFIRM_EMAIL_ERROR:
      return {
        ...state,
        isConfirmingEmail: false,
      };
    case types.LOGIN_INIT:
      return {
        ...state,
        isLoggingIn: true,
        fcmToken: action.payload.fcmToken,
      };
    case types.LOGIN_COMPLETE:
      return {
        ...state,
        isLoggingIn: false,
        ...action.payload,
      };
    case UPDATE_STORE_MANAGER_COMPLETE:
      return {
        ...state,
        user: {
          ...state.user,
          commerce: {
            ...state.user.commerce,
            dispatchers: [action.payload],
          },
        },
      };
    case POST_REQUEST_CHECKOUT_INIT:
      return {
        ...state,
        user: {
          ...state.user,
          latitude: action.data.latitude,
          longitude: action.data.longitude,
          phone: action.data.contact.phone,
        },
      };
    case POST_PAYMENT_INIT:
      return {
        ...state,
        user: {
          ...state.user,
          addresses:
            action.payload &&
            action.payload.user &&
            action.payload.user.addresses
              ? action.payload.user.addresses
              : state.user.addresses,
        },
      };
    case types.LOGIN_ERROR:
      return {
        ...state,
        isLoggingIn: false,
      };
    case types.GOOGLE_LOGIN_INIT:
      return {
        ...state,
        isLoggingSocial: true,
        fcmToken: action.payload.fcmToken,
      };
    case types.GOOGLE_LOGIN_COMPLETE:
      return {
        ...state,
        isLoggingSocial: false,
        ...action.payload,
      };
    case types.GOOGLE_LOGIN_ERROR:
      return {
        ...state,
        isLoggingSocial: false,
      };
    case types.FACEBOOK_LOGIN_INIT:
      return {
        ...state,
        isLoggingSocial: true,
        fcmToken: action.payload.fcmToken,
      };
    case types.FACEBOOK_LOGIN_COMPLETE:
      return {
        ...state,
        isLoggingSocial: false,
        ...action.payload,
      };
    case types.FACEBOOK_LOGIN_ERROR:
      return {
        ...state,
        isLoggingSocial: false,
      };
    case types.RECOVER_PASSWORD_INIT:
      return {
        ...state,
        isRecovering: true,
      };
    case types.RECOVER_PASSWORD_COMPLETE:
      return {
        ...state,
        isRecovering: false,
      };
    case types.RECOVER_PASSWORD_ERROR:
      return {
        ...state,
        isRecovering: false,
      };
    case types.RESET_PASSWORD_INIT:
      return {
        ...state,
        isReseting: true,
      };
    case types.RESET_PASSWORD_COMPLETE:
      return {
        ...state,
        isReseting: false,
      };
    case types.RESET_PASSWORD_ERROR:
      return {
        ...state,
        isReseting: false,
      };
    case types.CONTACT_INIT:
      return {
        ...state,
        isLoadingContact: true,
      };
    case types.CONTACT_INIT_COMPLETE:
      return {
        ...state,
        isLoadingContact: false,
      };
    case types.CONTACT_INIT_ERROR:
      return {
        ...state,
        isLoadingContact: false,
      };
    case types.LOGOUT:
      return initialState;
    case REHYDRATE:
      incoming = action.payload ? action.payload.accessStore : null;
      if (incoming) {
        return {
          ...incoming,
          isLoggingIn: false,
          isRegistering: false,
          isRecovering: false,
          isLoggingSocial: false,
          isChangingPassword: false,
        };
      }
      return state;
    default:
      return state;
  }
}
