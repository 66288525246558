import React, { useRef } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import { GOOGLE_MAPS_API_KEY } from "../constants";


// const apiKey = "AIzaSyB8setIKfX9310hBNUrNKITgZmc7UOl-qo";

const containerStyle = {
  width: "100%",
  height: "200px",
};

const center = {
  lat: -3.745,
  lng: -38.523,
};

const LoadingComponent = styled.div`
  min-height: 200px;
  width: 50%;
  text-align: center;
  display: flex;
  align-items: center;
  background-color: #eaeaea;
  padding-left: 50%;
  p {
    margin-left: -70px;
  }
`;

const libraries = ["places"];

const MyMap = ({
  onLoadScript,
  onUnmountScript,
  coords,
  coordsTitle,
  onLoadMap,
}) => {
  const scriptLoaded = useRef(
    typeof window.google === "object" && typeof window.google.maps === "object"
  );
  if (scriptLoaded.current)
    return (
      <GoogleMap
        onLoad={onLoadMap}
        mapContainerStyle={containerStyle}
        center={coords || center}
        zoom={17}
      >
        {/* Child components, such as markers, info windows, etc. */}
        <>
          {coords ? (
            <Marker
              animation="BOUNCE"
              title={coordsTitle}
              // onLoad={onLoad}
              position={coords}
            />
          ) : null}
        </>
      </GoogleMap>
    );
  return (
    <LoadScript
      onLoad={onLoadScript}
      onUnmount={onUnmountScript}
      libraries={libraries}
      loadingElement={
        <LoadingComponent>
          {" "}
          <p>Cargando mapa</p>
        </LoadingComponent>
      }
      googleMapsApiKey={GOOGLE_MAPS_API_KEY}
    >
      <GoogleMap
        onLoad={onLoadMap}
        mapContainerStyle={containerStyle}
        center={coords || center}
        zoom={17}
      >
        {/* Child components, such as markers, info windows, etc. */}
        <>
          {coords ? (
            <Marker
              animation="BOUNCE"
              title={coordsTitle}
              // onLoad={onLoad}
              position={coords}
            />
          ) : null}
        </>
      </GoogleMap>
    </LoadScript>
  );
};

export default MyMap;

MyMap.propTypes = {
  onLoadScript: PropTypes.func,
  onLoadMap: PropTypes.func,
  onUnmountScript: PropTypes.func,
  coords: PropTypes.shape({ lat: PropTypes.number, lng: PropTypes.number }),
  coordsTitle: PropTypes.string,
};

MyMap.defaultProps = {
  onLoadScript: undefined,
  onLoadMap: undefined,
  onUnmountScript: undefined,
  coords: undefined,
  coordsTitle: undefined,
};
