/* eslint-disable react/jsx-props-no-spreading */
import React, { Suspense, lazy, useEffect } from "react";
import PropTypes from "prop-types";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import { connect, useDispatch, useSelector } from "react-redux";
import firebase from "firebase/app";
import "firebase/messaging";
import {
  selectedLocalitySelector,
  unregisterFlagSelector,
} from "../ducks/general/selectors";
import { userSelector } from "../ducks/access/selectors";
import SuspenseLoader from "../components/SuspenseLoader";
import InfoModal from "../components/InfoModal";
import Snackbar from "../components/Snackbar";
import getStoreOrdersInit from "../ducks/store/actions";
import TermsAndConditions from "./TermsAndConditions";
import { unregister } from "../serviceWorker";
import { setUnregisterFlag } from "../ducks/general/actions";

const Home = lazy(() => import("./Home"));
const Login = lazy(() => import("./Login"));
const StoreDetail = lazy(() => import("./store/StoreDetail"));
const StoreList = lazy(() => import("./store/StoreList"));
const Register = lazy(() => import("./Register"));
const ContactInformation = lazy(() => import("./checkout/ContactInformation"));
const RecoverPassword = lazy(() => import("./RecoverPassword"));
const ChangePassword = lazy(() => import("./ChangePassword"));
const PurchaseHistory = lazy(() => import("./history/PurchaseHistory"));
const PrivacyPolicy = lazy(() => import("./PrivacyPolicy"));
const Catalog = lazy(() => import("./Catalog"));
const SearchResults = lazy(() => import("./SearchResults"));
const DetailOfAnOrder = lazy(() => import("./history/DetailOfAnOrder"));
const ConfirmationMessage = lazy(() => import("./message/ConfirmationMessage"));
const Profile = lazy(() => import("./profile/Profile"));
const ProductDetail = lazy(() => import("./store/ProductDetail"));
const Favorite = lazy(() => import("./profile/Favorite"));
const CategoryView = lazy(() => import("./CategoryView"));
const StoreHome = lazy(() => import("./store/StoreHome"));
const StoreSettings = lazy(() => import("./store/StoreSettings"));
const StoreOrderDetails = lazy(() => import("./store/StoreOrderDetails"));
const StorageCommerce = lazy(() => import("./store/StorageCommerce"));
const ChangePasswordProfile = lazy(() => import("./profile/ChangePassword"));
const WharehouserOrders = lazy(() => import("./warehouser/warehouserOrders/WharehouserOrders"));
const WharehouserOrderDetail = lazy(() => import("./warehouser/warehouserOrderDetail/WharehouserOrderDetail"));
const WharehousersStore = lazy(() => import("./store/warehousers/Warehousers"))
const WharehouserStoreDetail = lazy(() => import("./store/warehousers/WarehouserDetail"))

const Routes = ({ hasSelectedLocality, isAuthenticated, isCommerce, isWarehouser }) => {
  const dispatch = useDispatch();
  const unregisterFlag = useSelector(unregisterFlagSelector);
  useEffect(() => {
    // console.log("unregistered flag", unregisterFlag);
    if (!unregisterFlag) {
      unregister();
      dispatch(setUnregisterFlag(true));
      setTimeout(() => {
        window.location.reload();
      }, 500);
    }
    let unsubscribe;
    let messaging;
    if (firebase.messaging.isSupported()) {
      messaging = firebase.messaging();
    }
    if (messaging)
      unsubscribe = messaging.onMessage((payload) => {
        dispatch(getStoreOrdersInit(1, 10));
        if (navigator.serviceWorker && navigator.serviceWorker.controller)
          navigator.serviceWorker.controller.postMessage(payload);
      });

    return () => {
      if (messaging) unsubscribe();
    };
  }, [dispatch]);

  return (
    <Router>
      <Suspense fallback={<SuspenseLoader />}>
        <Switch>
          <RestrictedRoute
            exact
            path="/"
            component={Home}
            hasSelectedLocality={hasSelectedLocality}
            isAuthenticated={isAuthenticated}
            isCommerce={isCommerce}
            clientOnly
            withoutLocalityOnly
          />
          <Route exact path="/home/:id?" component={Home} />
          <Route exact path="/login" component={Login} />
          <Route exact path="/account-confirmation/:token" component={Login} />
          <Route exact path="/register" component={Register} />
          <Route exact path="/recover-password" component={RecoverPassword} />
          <Route
            exact
            path="/recovery-password/:token"
            component={ChangePassword}
          />
          <Route
            exact
            path="/change-password/"
            component={ChangePasswordProfile}
          />
          <RestrictedRoute
            exact
            path="/profile"
            component={Profile}
            hasSelectedLocality={hasSelectedLocality}
            privateRoute
            isAuthenticated={isAuthenticated}
            isCommerce={isCommerce}
            clientOnly
          />
          <Route
            exact
            path="/store-detail/:id/:initialPage?"
            component={StoreDetail}
            hasSelectedLocality={hasSelectedLocality}
            isAuthenticated={isAuthenticated}
            isCommerce={isCommerce}
            clientOnly
          />
          <Route exact path="/store-list" component={StoreList} />
          <Route exact path="/product-detail/:id?" component={ProductDetail} />
          <RestrictedRoute
            exact
            path="/purchase-history/:id"
            component={PurchaseHistory}
            isAuthenticated={isAuthenticated}
            hasSelectedLocality={hasSelectedLocality}
            privateRoute
          />
          <RestrictedRoute
            exact
            path="/catalog"
            component={Catalog}
            hasSelectedLocality={hasSelectedLocality}
            isAuthenticated={isAuthenticated}
            isCommerce={isCommerce}
            isWarehouser={isWarehouser}
            clientOnly
          />
          <RestrictedRoute
            exact
            path="/store/warehousers"
            component={WharehousersStore}
            hasSelectedLocality={hasSelectedLocality}
            isAuthenticated={isAuthenticated}
            isCommerce={isCommerce}
            isWarehouser={isWarehouser}
            commerceOnly
          />
          <RestrictedRoute
            exact
            path="/store/warehousers/:id"
            component={WharehouserStoreDetail}
            hasSelectedLocality={hasSelectedLocality}
            isAuthenticated={isAuthenticated}
            isCommerce={isCommerce}
            isWarehouser={isWarehouser}
            commerceOnly
          />
          <RestrictedRoute
            exact
            path="/catalog/:initialPage/:idBrand?" // id --> initialPage
            component={Catalog}
            hasSelectedLocality={hasSelectedLocality}
            isAuthenticated={isAuthenticated}
            isCommerce={isCommerce}
            clientOnly
          />
          <RestrictedRoute
            exact
            path="/search-results"
            component={SearchResults}
            hasSelectedLocality={hasSelectedLocality}
            isAuthenticated={isAuthenticated}
            isCommerce={isCommerce}
            clientOnly
          />
          <RestrictedRoute
            exact
            path="/store-home/:initialPage?"
            component={StoreHome}
            isAuthenticated={isCommerce}
            isCommerce={isCommerce}
            commerceOnly
          />
          <RestrictedRoute
            exact
            path="/store-settings"
            component={StoreSettings}
            isAuthenticated={isCommerce}
            isCommerce={isCommerce}
            commerceOnly
          />
          <RestrictedRoute
            exact
            path="/store-order-details"
            component={StoreOrderDetails}
            isAuthenticated={isCommerce}
            isCommerce={isCommerce}
            commerceOnly
          />
          <RestrictedRoute
            exact
            path="/storage-commerce/:initialPage?"
            component={StorageCommerce}
            isAuthenticated={isCommerce}
            isCommerce={isCommerce}
            commerceOnly
          />
          <RestrictedRoute
            exact
            path="/warehouser/orders"
            component={WharehouserOrders}
            hasSelectedLocality={hasSelectedLocality}
            isAuthenticated={isAuthenticated}
            isCommerce={isCommerce}
            isWarehouser={isWarehouser}
            warehouserOnly
          />
          <RestrictedRoute
            exact
            path="/warehouser/orders/:id"
            component={WharehouserOrderDetail}
            hasSelectedLocality={hasSelectedLocality}
            isAuthenticated={isAuthenticated}
            isCommerce={isCommerce}
            isWarehouser={isWarehouser}
            warehouserOnly
          />
          <RestrictedRoute
            path="/category-view/:category/:subcategory?/:family?"
            component={CategoryView}
            hasSelectedLocality={hasSelectedLocality}
            isAuthenticated={isAuthenticated}
            isCommerce={isCommerce}
            clientOnly
          />
          <RestrictedRoute
            exact
            path="/detail-order/:id"
            component={DetailOfAnOrder}
            isAuthenticated={isAuthenticated}
            hasSelectedLocality={hasSelectedLocality}
            privateRoute
            clientOnly
          />
          <RestrictedRoute
            exact
            path="/order-details/:id"
            component={DetailOfAnOrder}
            isAuthenticated={isAuthenticated}
            hasSelectedLocality={hasSelectedLocality}
            privateRoute
            isCommerce={isCommerce}
            clientOnly
          />
          <Route exact path="/favorite" component={Favorite} />
          <Route
            exact
            path="/confirmation-message/:id?"
            component={ConfirmationMessage}
          />
          <RestrictedRoute
            exact
            path="/contact-information/"
            component={ContactInformation}
            isAuthenticated={isAuthenticated}
            hasSelectedLocality={hasSelectedLocality}
            isCommerce={isCommerce}
            privateRoute
            clientOnly
          />
          <Route exact path="/privacy-policy" component={PrivacyPolicy} />
          <Route
            exact
            path="/terms-and-conditions"
            component={TermsAndConditions}
          />
        </Switch>
        <InfoModal />
        <Snackbar />
      </Suspense>
    </Router>
  );
};

Routes.propTypes = {
  hasSelectedLocality: PropTypes.bool.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  isCommerce: PropTypes.bool.isRequired,
  isWarehouser: PropTypes.bool.isRequired
};

function mapStateToProps(state) {
  const hasSelectedLocality = selectedLocalitySelector(state);
  const user = userSelector(state);
  return {
    hasSelectedLocality:
      hasSelectedLocality !== null && hasSelectedLocality !== undefined,
    isAuthenticated:
      user !== undefined &&
      user !== null &&
      user.email !== undefined &&
      user.email !== null,
    isCommerce:
      user !== undefined &&
      user !== null &&
      user.commerce !== undefined &&
      user.commerce !== null,
    isWarehouser: 
      user &&
      Array.isArray(user.roles) &&
      user.roles.includes('ROLE_WAREHOUSE_WORKER'),
  };
}
export default connect(mapStateToProps)(Routes);

const RestrictedRoute = ({
  component: Component,
  hasSelectedLocality,
  isAuthenticated,
  privateRoute,
  isCommerce,
  clientOnly,
  commerceOnly,
  withoutLocalityOnly,
  isWarehouser,
  warehouserOnly,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        if (!isAuthenticated) {
          return <Redirect exact to="/login" />;
        }
        if (isCommerce && (rest.path === "/catalog" || rest.path === "/")){
          return <Redirect exact to="/storage-commerce" />;
        }
        if (isWarehouser && rest.path === "/catalog"){
          return <Redirect exact to="/warehouser/orders" />;
        }
        if (!isWarehouser && warehouserOnly && rest.path !== "/login") {
          return <Redirect exact to="/login" />;
        }
        if (!isCommerce && commerceOnly && rest.path !== "/login") {
          return <Redirect exact to="/login" />;
        }
        if (isCommerce && clientOnly && rest.path !== "/store-home") {
          return <Redirect exact to="/store-home" />;
        }
        if (withoutLocalityOnly && hasSelectedLocality) {
          return <Redirect exact to="/catalog" />;
        }
        if (isCommerce && commerceOnly) {
          return <Component {...props} />;
        }
        if (hasSelectedLocality) {
          if (privateRoute && !isAuthenticated && rest.path !== "/login") {
            return <Redirect exact to="/login" />;
          }
          return <Component {...props} />;
        }
        if (!hasSelectedLocality) {
          if (privateRoute && !isAuthenticated && rest.path !== "/login") {
            return <Redirect exact to="/login" />;
          }
          return <Component {...props} />;
        }
        if (rest.path !== "/") {
          return <Redirect exact to="/" />;
        }
        return <Component {...props} />;
      }}
    />
  );
};

RestrictedRoute.propTypes = {
  hasSelectedLocality: PropTypes.bool,
  privateRoute: PropTypes.bool,
  isAuthenticated: PropTypes.bool.isRequired,
  clientOnly: PropTypes.bool,
  commerceOnly: PropTypes.bool,
  isCommerce: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  component: PropTypes.object.isRequired,
  withoutLocalityOnly: PropTypes.bool,
  isWarehouser: PropTypes.bool,
  warehouserOnly: PropTypes.bool
};

RestrictedRoute.defaultProps = {
  privateRoute: false,
  clientOnly: false,
  commerceOnly: false,
  isCommerce: false,
  hasSelectedLocality: false,
  withoutLocalityOnly: false,
  isWarehouser: false,
  warehouserOnly: false
};

// const UnloggedRoute = ({
//   component: Component,
//   isAuthenticated,
//   isProfileCompleted,
//   ...rest
// }) => (
//   <Route
//     {...rest}
//     render={(props) => {
//       if (isAuthenticated) {
//         if (rest.path === "/contact") {
//           return <Redirect exact to="/contact-authenticated" />;
//         }
//         if (isProfileCompleted && rest.path !== "/dashboard") {
//           return <Redirect to="/dashboard" {...props} />;
//         }
//         if (rest.path !== "/profile") {
//           return <Redirect exact to="/profile" />;
//         }
//         return <Component {...props} />;
//       }
//       return <Component {...props} />;
//     }}
//   />
// );