/* eslint-disable react/no-unescaped-entities */
import React from "react";
import styled from "styled-components";
import Layout from "../components/layout/Layout";

const Container = styled.div`
  min-height: calc(100vh - 70px);
  padding: 40px;
`;

export default function TermsAndConditions() {
  return (
    <Layout>
      <Container>
        <div className="legal-container">
          <h3>TÉRMINOS Y CONDICIONES</h3>
          <p>
            Los presentes Términos y Condiciones rigen el uso que toda persona
            natural o jurídica, o representante en cualquier forma de los
            mismos, hace de la Plataforma y de cualquier Servicio que sea de
            propiedad o esté controlado por Yoper. Este documento contiene
            información legal que le recomendamos leer completamente en conjunto
            con la Política de Privacidad. Por medio de la aprobación de los
            presentes Términos y Condiciones, se entiende que el Usuario los ha
            leído y aceptado, en todas sus partes, y entiende que estos le son
            legalmente vinculantes y obligatorios. Por tanto, acepta las
            condiciones de utilización y aprovechamiento de la Plataforma,
            Contenido y los Servicios. En caso contrario, el Usuario deberá
            abstenerse de acceder a la Plataforma y Servicios, ya sea directa o
            indirectamente, y de utilizar cualquier información o servicio
            provisto por la misma.
          </p>

          <h3>1. Definiciones</h3>
          <p>
            Los términos cuya primera letra figura en mayúscula (salvo cuando se
            deba exclusivamente a que inician una oración o se trate de un
            nombre propio), tienen el significado que se les asigna a
            continuación, o el que se les asigne expresamente en estos Términos
            de Uso o en la Política de Privacidad:
            <br />
            <br />
            Comercio(s) significa toda persona natural o jurídica que constituya
            un comercio minorista seleccionado, quienes podrán ofrecer sus
            productos a través de la Plataforma y a los cuales los Usuarios
            podrán comprar Productos.
            <br />
            <br />
            Vendedor (es) significa el personal, contratado por Comercio, que
            efectúa le gestión de la compra de Productos de los Comercios.
            <br />
            <br />
            Vendedor (es) significa el personal, contratado por Comercio, que
            efectúa le gestión de la compra de Productos de los Comercios.
            <br />
            <br />
            Contenido significa todo tipo de información, ya sea gráfica o
            escrita, imágenes, audio y video, incluyendo pero no limitándose a
            la ubicación, anuncios, comentarios, noticias, datos, guiones,
            gráficos, dibujos o imágenes, textos, diseño, esquemas, mapas y
            características interactivas presentadas por Yoper en la Plataforma
            (y cualquier software y códigos informáticos subyacentes), así como
            también programas o algoritmos computacionales, módulos de
            programación, manuales de operación, sea que dicho Contenido es
            generado, provisto o de cualquier otra forma producido o
            suministrado por Yoper, los Usuarios o terceros.
            <br />
            <br />
          </p>
          <p>
            Yoper significa Socio Tech SpA.
            <br />
            <br />
            Equipo significa los teléfonos móviles y smartphones, tablets,
            computadores y cualquier otro aparato electrónico por el cual se
            pueda acceder a la Plataforma.
            <br />
            <br />
            Material de Usuarios significa toda clase de fotografías, videos,
            comentarios, comunicaciones u otros contenidos generado por
            Usuarios, que hayan sido subidos o cargados a la Plataforma.
            <br />
            <br />
            Orden de Compra significa el pedido particular de un Usuario para la
            compra de determinados Productos.
            <br />
            <br />
            Perfil significa la cuenta personal creada por cada Usuario que
            acredita el registro en la Plataforma, con base a la información
            personal provista por el mismo a Yoper, la cual incluye su nombre,
            apellidos, rol único tributario, fecha de nacimiento, dirección,
            teléfono, nombre de usuario, correo electrónico y contraseña.
            <br />
            <br />
            Plataforma significa el sitio web, aplicaciones móviles y plataforma
            operada por Yoper, por los cuales los Usuario(s) y Vendedor(es)
            podrán acceder a los Servicios.
            <br />
            <br />
            Política de Privacidad significa las políticas de privacidad de
            Yoper, las cuales se encuentran debidamente publicadas en la
            Plataforma.
            <br />
            <br />
            Producto(s) significa prendas de vestir u otros artículos ofrecidos
            en la plataforma.
            <br />
            <br />
            Repartidor(es) significa el personal, contratado por Comercio(s) o
            un tercero externo, que efectúa la entrega de los Productos.
            <br />
            <br />
            Servicios significa todos los servicios ofrecidos por medio de la
            Plataforma, así como los demás servicios provistos por Yoper, a los
            cuales los Usuarios pueden acceder por medio de la Plataforma y sus
            Equipos.
            <br />
            <br />
            Términos y Condiciones, Términos de Uso o Acuerdo significa los
            presentes Términos y Condiciones de Yoper.
            <br />
            <br />
            Usuario(s) significa toda persona natural o jurídica, o
            representante en cualquier forma de estos, que utilice o que se
            encuentra registrado como tal en la Plataforma, y que podrán
            clasificarse en Navegadores, Participantes, incluyendo los
            postulantes a puestos de trabajo ofrecidos en la plataforma, y
            Colaboradores, de acuerdo a lo señalado en los presentes Términos y
            Condiciones. El término “Usuario” abarca todas y cualquiera de las
            referidas categorías.
          </p>

          <h3>2. Aceptación de los términos de uso</h3>

          <p>
            Mediante la creación de un Perfil y/o la utilización en cualquier
            forma de los Servicios y Plataforma, el Usuario acepta todos los
            Términos de Uso aquí contenidos y la Política de Privacidad.
            Asimismo, se entiende que acepta todas las demás reglas de
            operación, políticas y procedimientos que puedan ser publicados por
            Yoper en la Plataforma, cada uno de los cuales se incorpora por
            referencia.
            <br />
            <br />
            Sin perjuicio de lo anterior, algunos servicios que se ofrecen en la
            Plataforma pueden estar sujetos a términos y condiciones
            adicionales. En ese caso, el uso que el Usuario haga de dichos
            servicios estará sujeto, además, a los referidos términos y
            condiciones adicionales, que se incorporan a los presentes Términos
            de Uso por medio de esta referencia. En caso de que el Usuario no
            los acepte, deberá abstenerse de utilizar en cualquier forma dichos
            servicios.
          </p>

          <h3>3. Sobre Yoper</h3>

          <p>
            Yoper es una plataforma de comunicaciones que facilita la conexión
            entre Usuarios que buscan hacer pedidos de Productos de Comercios, y
            los Vendedores que materializan las Órdenes de Compra por cuenta de
            Usuarios que han autorizado la compra y coordinan la entrega de los
            mismos a los Repartidores. El valor pagado por sobre el precio de
            venta de los Productos corresponde al pago de los servicios
            prestados por los Repartidores. El Usuario reconoce y acepta que el
            Vendedor actúa por cuenta del Usuario en la compra de los Productos,
            y que Yoper sólo tiene relación con él en lo que se refiere a
            proporcionar el soporte que se establece expresamente en el presente
            documento, salvo los casos en que los Vendedores o Repartidores
            prestan sus servicios bajo vínculo de subordinación y dependencia de
            Yoper. El Usuario acepta que Yoper pudiera cobrar comisiones por los
            servicios prestados a éstos.
            <br />
            <br />
            Yoper no tiene control alguno sobre la calidad, tiempo, legalidad,
            fallas o cualquier otro aspecto que se encuentre comprendido dentro
            de los servicios de los Vendedores y Repartidores, sino sólo de
            aquellos que sean contratados por Yoper.
          </p>

          <h3>
            4. Liberación de responsabilidad de Yoper por actos de terceros
          </h3>

          <p>
            La responsabilidad del cumplimiento de las obligaciones de los
            Vendedores y Repartidores recaerá en Yoper solamente respecto a
            quienes presten sus servicios bajo subordinación y dependencia de
            Yoper. En el resto de los casos, dicha responsabilidad recaerá en el
            Comercio, Vendedor o Repartidor correspondiente. En caso de ser
            necesario, Yoper cederá al Usuario los derechos que pudiere tener en
            contra de los Comercios, Vendedores o Repartidores, según
            corresponda, por los perjuicios que éstos pudieren haber provocado.
            <br />
            <br />
            Ni Yoper, ni sus afiliados o licenciantes serán responsables ante
            cualquier reclamo, lesión o daño que surja en relación con los actos
            u omisiones de cualquier Comercio, Vendedor o Repartidor, salvo lo
            indicado en el párrafo anterior. En caso de presentarse una disputa
            con uno o más Comercios, Vendedores o Repartidores, el Usuario
            libera a Yoper, sus directores, empleados, subsidiarias, afiliados,
            agentes y representantes de todos los reclamos, responsabilidades,
            costos, incluyendo sin limitación honorarios de abogados, pérdidas o
            daños de cualquier clase o naturaleza, directos o indirectos, que
            surjan a consecuencia de tales disputas.
            <br />
            <br />
            En este sentido, el Usuario declara entender que Yoper no es más que
            un mero facilitador que pone a disposición de éstos el uso de una
            Plataforma por medio de la cual interactúa directamente con el o los
            Comercios; que en ningún caso entiende comprar los Productos a Yoper
            y que, en caso de existir cualquier tipo de daño o vulneración de
            derechos del consumidor, esto será materia a reclamar directamente
            en contra del Comercio, no afectando de manera alguna a Yoper.
          </p>
          <h3>5. Uso de materiales</h3>
          <p>
            Salvo que se disponga lo contrario, todos los derechos sobre el
            Contenido son de propiedad de Yoper. Los Usuarios se encuentran
            autorizados para utilizar la Plataforma, así como para descargar
            material exhibido en ella, pero únicamente para uso personal,
            estando expresamente prohibido su copia, reproducción, publicación,
            descarga, codificación, modificación, traducción, interpretación,
            exhibición, distribución, transmisión, transmisión o difusión en
            medios de comunicación, sin la autorización previa, expresa y por
            escrito de Yoper. Los derechos de autor y otros derechos de
            propiedad sobre el Contenido, con independencia de su descarga por
            parte de los Usuarios, son de propiedad de Yoper, haciendo expresa
            reserva del ejercicio de todas las acciones tanto civiles como
            penales destinadas al resguardo de sus legítimos derechos de
            propiedad intelectual e industrial.
          </p>
          <h3>6. Material generado por usuarios</h3>
          <p>
            En caso de que la Plataforma permitiera la publicación, subida o
            carga de Material de Usuarios, éstos serán considerados en todo
            momento como no confidenciales. Además, mediante la publicación o
            carga de los mismos a Yoper, el Usuario otorga a Yoper un derecho
            perpetuo, mundial, no exclusivo, libre de regalías, sublicenciable y
            transferible para usar, reproducir, distribuir, modificar, mostrar y
            realizar el envío de Material de Usuarios, para la promoción y apoyo
            de Yoper. El Usuario otorga a cada Usuario una licencia no exclusiva
            para acceder a su Material de Usuario a través de la Plataforma y de
            mostrar y representar públicamente dicho Material de Usuario, en
            virtud de estos Términos y Condiciones.
            <br />
            <br />
            En relación con lo anterior, el Usuario declara acerca del Material
            de Usuario que: (i) es titular de los derechos de autor sobre los
            mismos, o que tiene la autorización del propietario de estos para su
            publicación en Yoper; (ii) no es ilegal, obsceno, difamatorio,
            injurioso, pornográfico, odioso, racista, o inadecuado por cualquier
            motivo; (iii) no dañará la reputación de Yoper o cualquier tercero;
            y (iv) no se hace pasar por otra persona. Yoper se reserva el
            derecho de eliminar cualquier Material de Usuario a su entera
            discreción y sin previo aviso ni responsabilidad hacia el Usuario o
            cualquier otra persona.
            <br />
            <br />
            Yoper no avala ni apoya ninguna clase de Material de Usuario,
            recomendación o consejo en la misma, renunciando el Usuario a seguir
            cualquier clase de responsabilidad de Yoper con relación a cualquier
            Material de Usuario. El Usuario entiende y acepta que el Material de
            Usuario puede contener información que es inexacta u ofensiva,
            renunciando, desde ya, a reclamar o presentar cualquier clase de
            recurso en contra Yoper con respecto a la misma.
            <br />
            <br />
            Yoper puede proporcionar enlaces a sitios web pertenecientes o
            gestionados por terceros, sin que por este hecho pueda entenderse,
            bajo ninguna circunstancia, que Yoper respalda el contenido,
            productos o servicios disponibles en dichos sitios web, y que no es
            responsable de su contenido o su seguridad. El enlace o conexión del
            Usuario a cualquier otro sitio web es de su exclusiva
            responsabilidad.
          </p>
          <h3>7. Cuenta de usuario</h3>
          <p>
            Se podrá crear una sola cuenta por persona y la persona que crea un
            Usuario es la única persona autorizada para el acceso a la
            plataforma por intermedio de dicho Usuario. El Usuario es
            responsable de mantener la confidencialidad de cualquier contraseña
            o número de cuenta proporcionado por el Usuario o Yoper para acceder
            a la Plataforma. Cada Usuario es el único y absoluto responsable de
            todas las actividades que ocurran bajo su contraseña o cuenta. Yoper
            no tiene control sobre el uso de la cuenta de un Usuario y renuncia
            expresamente a cualquier responsabilidad derivada de la misma. En
            caso de que un Usuario sospeche que un tercero pudiera estar
            accediendo a la plataforma bajo su cuenta de Usuario o utilizando su
            contraseña, notificará a Yoper inmediatamente.
            <br />
            <br />
            Usted puede optar por no recibir mensajes de texto desde Yoper,
            enviando un correo electrónico a contacto@yoper.cl ó
            contacto@yoper.com.ar ó contacto@yoper.com.pe. Si usted proporciona
            su número de teléfono celular, por ese hecho entrega expresamente su
            consentimiento para que Yoper haga uso de este para llamadas y envío
            de mensajes de textos con el fin de ofrecer los Servicios.
          </p>
          <h3>8. Renuncia</h3>
          <p>
            El uso de Yoper es bajo el propio riesgo del Usuario. Los Servicios
            se prestan tal como son ofrecidos, sin garantías de ningún tipo,
            explícita o implícita, incluyendo, pero no limitada a, las garantías
            de comercialización, idoneidad del Producto para un propósito
            particular y no infracción. Yoper no efectúa ninguna clase de
            representación o garantía relativa a la exactitud o totalidad del
            contenido ofrecido a través del uso de Yoper o sobre el contenido de
            cualquier sitio web vinculado al uso de Yoper, ni de los Comercios.
            Yoper no asume ninguna responsabilidad derivada de (i) errores o
            imprecisiones del contenido; (ii) lesiones personales o daños a la
            propiedad, de cualquier naturaleza, como resultado de su acceso y
            uso de la Plataforma; (iii) acceso no autorizado o uso de servidores
            seguros de Yoper y/o cualquier información personal y/o financiera
            almacenada en ellos; (iv) la negativa de un Comercio de entregar los
            Productos comprados.
            <br />
            <br />
            Yoper no garantiza que la Plataforma funcionará libre de errores o
            que esté libre de virus u otros productos peligrosos. Si el uso de
            la Plataforma deriva en la necesidad de servicio técnico o reemplazo
            de equipo o datos, Yoper no será responsable de dichos costos.
            Yoper, en la medida máxima permitida por la ley, renuncia a toda
            garantía, explícita o implícita, incluyendo sin limitación las
            garantías de comercialización, no violación de derechos de terceros
            y la garantía de idoneidad para un propósito particular. Yoper no
            garantiza, bajo ninguna circunstancia, la exactitud, confiabilidad,
            exhaustividad y actualidad de los contenidos, servicios, soporte,
            software, textos, gráficos o vínculos. Yoper y sus filiales y
            proveedores de licencias no garantiza, bajo ninguna circunstancia,
            que la información personal suministrada por el Usuario pueda ser
            objeto de apropiación indebida, interceptada, borrada, destruida o
            usada por terceros.
          </p>
          <h3>9. Reportes y denuncias</h3>
          <p>
            Los Usuarios podrán reportar a Yoper cualquier conducta o contenido
            que pueda infringir estos Términos de Uso, la Política de Privacidad
            o la legislación aplicable. Para tal efecto, los usuarios podrán
            “Reportar” enviando un correo electrónico a contacto@yoper.cl ó
            <strong>contacto@yoper.com.ar</strong> ó{" "}
            <strong>contacto@yoper.com.pe.</strong>
            <br />
            <br />
            Recibido un reporte, Yoper, a su entera discreción, determinará si
            corresponde la eliminación o modificación del Contenido.
          </p>
          <h3>10. Limitación de responsabilidad</h3>
          <p>
            El Usuario acepta que Yoper no se hace responsable por ningún daño
            directo, indirecto, lucro cesante, daño emergente, daño incidental,
            especial o consecuencial, proveniente de o en relación con (i) el
            uso de la Plataforma; (ii) la responsabilidad de cualquier Comercio,
            Vendedor o Repartidor que no preste sus servicios bajo subordinación
            y dependencia de Yoper; (iii) en relación con el rendimiento o
            navegación en la Plataforma o sus enlaces a otros sitios web,
            incluso si Yoper ha sido informado de la posibilidad de tales daños;
            (iv) la calidad y cantidad de los Productos entregados al Usuario;
            (v) la seguridad del uso de los Productos adquiridos; o (vi) la
            información errónea, incompleta o incorrecta sobre los Productos
            ofrecidos por un Comercio.
            <br />
            <br />
            Además, el Usuario acepta que Yoper no se hace responsable de los
            daños derivados de la interrupción, suspensión o terminación de los
            Servicios, incluyendo sin limitación daño directo, indirecto, lucro
            cesante, daño emergente, daño incidental, especial o consecuencial,
            aun cuando dicha interrupción, suspensión o terminación estuviera o
            no justificada. En ningún caso la responsabilidad total de Yoper
            ante el Usuario por cualquier clase de pérdidas podrá exceder los
            montos pagados por éste a Yoper.
          </p>
          <h3>11. Indemnidad</h3>
          <p>
            El Usuario se obliga a defender, indemnizar y mantener indemne a
            Yoper, sus funcionarios, directores, empleados, agentes y afiliados,
            de y contra cualquier pérdida, reclamos, acciones, costos, daños,
            sanciones, multas y gastos, incluyendo sin limitación honorarios de
            abogados, que surjan de, relacionados con o resultantes del uso no
            autorizado de la Plataforma por parte del Usuario, o de cualquier
            incumplimiento de estos Términos y Condiciones, incluyendo sin
            limitación, cualquier violación de cualquier ley, ordenanza, orden
            administrativa, norma o reglamento. Yoper dará aviso, a la brevedad,
            de cualquier reclamación, demanda o procedimiento, y tendrá derecho
            a asumir la defensa respecto de cualquier reclamación, demanda o
            procedimiento.
          </p>
          <h3>12. Terminación</h3>
          <p>
            A su sola discreción, Yoper puede modificar o interrumpir la
            Plataforma, o puede modificar, suspender o interrumpir su acceso o
            el soporte, por cualquier razón, con o sin previo aviso y sin
            ninguna responsabilidad los Usuarios o cualquier tercero. Aun cuando
            un Usuario pierda el derecho a utilizar la Plataforma, los presentes
            Términos y Condiciones serán ejecutables en su contra.
            <br />
            <br />
            El Usuario podrá terminar estos Términos y Condiciones en cualquier
            momento, dejando de utilizar la Plataforma, sobreviviendo todas las
            disposiciones que por su naturaleza debieran sobrevivir para surtir
            efecto.
            <br />
            <br />
            La terminación de los Servicios y/o el cierre de la cuenta del
            Usuario, por cualquier causa, no generará compensación ni
            indemnización en su favor por parte de Yoper.
          </p>
          <h3>13. Resolución de conflictos</h3>
          <p>
            Cualquier dificultad o controversia que se produzca entre los
            contratantes respecto de la aplicación, interpretación, duración,
            validez o ejecución de este Acuerdo será sometida a arbitraje,
            conforme al Reglamento Procesal de Arbitraje del Centro de Arbitraje
            y Mediación de Santiago, vigente al momento de solicitarlo.
            <br />
            <br />
            Las partes confieren poder especial irrevocable a la Cámara de
            Comercio de Santiago A.G., para que, a petición escrita de
            cualquiera de ellas, designe a un árbitro arbitrador de entre los
            integrantes del cuerpo arbitral del Centro de Arbitraje y Mediación
            de Santiago.
            <br />
            <br />
            En contra de las resoluciones del arbitrador no procederá recurso
            alguno, renunciando las partes expresamente a ellos. El árbitro
            queda especialmente facultado para resolver todo asunto relacionado
            con su competencia y/o jurisdicción.
          </p>
          <h3>14. Ley aplicable y divisibilidad</h3>
          <p>
            Estos Términos y Condiciones estarán regidos por las leyes de la
            República de Chile.
            <br />
            <br />
            Los Términos de Uso y la Política de Privacidad constituyen el
            acuerdo completo entre el Usuario y Yoper con respecto a los
            Servicios, uso de la Plataforma y del contenido, y sustituyen todas
            las comunicaciones y propuestas previas o contemporáneas (ya sean
            escritas, orales o electrónicas) entre el Usuario y Yoper con
            respecto a la Plataforma y a los Servicios.
          </p>
          <h3>15. No agencia</h3>
          <p>
            Por el presente Acuerdo no se crea ninguna relación contractual de
            agencia, asociación, empresa conjunta, empleado-empleador o
            franquiciador-franquiciado.
          </p>
          <h3>16. Descuentos, promociones y beneficios de las tiendas</h3>
          <p>
            Yoper no aplica los descuentos y promociones que se muestran en
            tiendas. Los precios que cobramos son los que publicamos,
            independiente de lo que encontremos en la tienda. Sin embargo,
            siempre tenemos promociones con diferentes marcas.
            <br />
            <br />
            Yoper tampoco aplica los beneficios de la compra como acumulación de
            puntos, uso de tarjetas de cliente frecuente, redondeos o similares.
          </p>
          <h3>17. Modificaciones</h3>
          <p>
            Yoper podrá, a su sola y absoluta discreción, cambiar por sí y sin
            aviso previo los estos Términos y Condiciones. Sin embargo, tales
            cambios sólo se aplicarán desde el momento en que sean publicados en
            la Plataforma y regirán para las transacciones que se celebren con
            posterioridad a su entrada en vigor, sin alterar las transacciones
            celebradas con anterioridad.
          </p>
          <h3>18. Varios</h3>
          <p>
            Estos Términos de Uso son personales, y no se pueden ceder,
            transferir ni sublicenciar, excepto con el consentimiento previo por
            escrito de Yoper. Yoper podrá ceder, transferir o delegar cualquiera
            de sus derechos y obligaciones en virtud de estos Términos de Uso
            sin el consentimiento del Usuario.
            <br />
            <br />A menos que se especifique lo contrario en estos Términos de
            Uso, todos los avisos o modificaciones serán considerados
            debidamente entregados desde el momento de su publicación en la
            Plataforma, o bien desde el momento en que sea notificado al
            Usuario, según corresponda.
          </p>
          <h3>19. Contacto</h3>
          <p>
            Si el Usuario tiene alguna duda respecto de los Términos y
            Condiciones, Política de Privacidad, uso de la Plataforma o de su
            Perfil, podrá ponerse en contacto con Yoper vía el correo
            electrónico
            <strong>contacto@yoper.cl</strong> ó{" "}
            <strong>contacto@yoper.com.ar</strong> ó{" "}
            <strong>contacto@yoper.com.pe</strong>. Los mensajes serán atendidos
            a la mayor brevedad posible.
          </p>
          <h3>20. Términos y condiciones MercadoPago en Yoper</h3>
          <p>
            Los usuarios de Yoper podrán acceder a esta modalidad de pago la
            cual consiste en realizar tus compras en{" "}
            <strong>https://yoper.cl</strong> a través del Carrito de compras y
            Botón de pago
            <br />
            <br />
            La presente sección se aplica específicamente al uso del Botón de
            pago ofrecido por Mercado Pago, en adelante el Botón de pago.
            <br />
            <br />
            <strong>a)</strong> Requisitos. Para utilizar el Botón de pago se
            requiere ser un Usuario previamente inscrito y Mercado Pago se
            reserva el derecho de solicitar información adicional o verificación
            de los Datos Personales. Los Usuarios que utilicen el Botón de pago
            para vender, se denominarán en adelante los &quot;Vendedores&quot; y
            los Usuarios que las utilicen el Botón de pago para comprar, se
            denominarán en adelante los &quot;Compradores&quot;.
            <br />
            <br />
            <strong>b)</strong> Prohibiciones. Se prohíbe la utilización de
            cualquiera del Botón de pago , en cualquier sitio Web que (i) tenga
            contenido o permita o realice cualquier actividad contraria a las
            disposiciones legales y administrativas, la moral y las buenas
            costumbres; o (ii) promueva o tenga contenidos de sexo en el que
            participen menores de 18 años, relacionados con pedofilia,
            pornografía, desnudos de menores, ya sean reales o simulados; o
            (iii) de cualquier modo involucre menores de 18 años, o se trate de
            películas o fotografías que hayan sido tomadas de forma ilegal de
            menores de 18 años o sin el consentimiento de las personas que en
            ellas aparecen; o (iv) promueva la violencia de cualquier tipo, la
            discriminación de cualquier tipo, prostitución, lavado de dinero,
            tráfico de armas, de personas o de animales, u otras actividades
            ilegales; y/u (v) ofrezca contenidos que violen cualquier
            legislación vigente, en especial aquellas referidas a la protección
            de derechos de propiedad intelectual, piratería de software, etc.
            Mercado Pago podrá dar por terminado este Contrato y/o suspender,
            cancelar, temporal o definitivamente una Cuenta, sin notificación
            previa, en caso que determine que el sitio Web donde se utiliza el
            Botón de pago, no se adecua en todo o en parte a los estándares
            antes mencionados o en caso de descubrirse o sospecharse
            comportamientos fraudulentos o que atenten contra la imagen de
            Mercado Pago, sus accionistas, controlantes, controladas, filiales o
            subsidiarias, funcionarios, empleados, directores, agentes, y/o sus
            usuarios y/o terceros. Asimismo, Mercado Pago se reserva el derecho
            de iniciar las acciones judiciales o extrajudiciales que estime
            pertinentes.
            <br />
            <br />
            <strong>c) </strong>Costos de recibir dinero para ser aplicado a
            determinada operación comercial y cobrar con Mercado Pago. Conoce
            los cargos por utilizar a Plataforma para cobrar y recibir dinero,
            así como los demás cargos por operar a través de ella.
            <br />
            <br />
            <strong>d) </strong>Códigos del Botón de pago. Luego de la apertura
            de su Cuenta el Usuario podrá colocar en su sitio Web, alguno de los
            códigos facilitados por Mercado Pago para generar el Botón de pago,
            con los parámetros necesarios para dirigir a sus usuarios a nuestro
            Sitio (los &quot;Códigos&quot;). Los códigos mencionados
            anteriormente podrán tener distintas formas, según la disponibilidad
            de Mercado Pago y en ningún caso podrán ser modificados por el
            Usuario. Mercado Pago se reserva el derecho de actualizar o
            modificar periódicamente el contenido y/o apariencia del Botón de
            pago y de las URL donde estén direccionados los Códigos. Mercado
            Pago no será responsable si el Código fuera modificado y con ello se
            cause un daño o perjuicio a un tercero.
            <strong>e)</strong> Yoper será el único y exclusivo responsable por
            los contenidos de su sitio Web. En caso que uno o más usuarios o
            algún tercero inicie cualquier tipo de reclamo o acción legal,
            administrativa o extrajudicial contra Mercado Pago, sus accionistas,
            controlantes, controladas, filiales o subsidiarias, funcionarios,
            empleados, directores, agentes y empleados, el Vendedor involucrado
            en dichos reclamos o acciones exime y se compromete a mantener
            indemne de toda responsabilidad a Mercado Pago y a sus accionistas,
            controlantes, controladas, filiales o subsidiarias, funcionarios,
            empleados, directores, agentes, representantes y apoderados.
            Asimismo, ambas Partes declaran ser contratistas independientes, sin
            ninguna relación societaria o comercial fuera de la pactada en el
            presente acuerdo, y cada una de ellas mantendrá indemne a la otra
            por cualquier reclamo derivado de las obligaciones impositivas,
            laborales o previsionales que estuvieran a su cargo. Ninguna de las
            partes inducirá a error a persona alguna sobre su carácter de parte
            contractual independiente de la otra ni obligará a la otra parte
            frente a terceros. El incumplimiento de esta obligación por el
            Vendedor habilitará a Mercado Pago a rescindir el presente acuerdo
            de pleno derecho en cualquier momento.
          </p>
          <h3>21. Despachos y Devoluciones</h3>
          <p>
            Todas nuestras ventas quedan sujetas al stock de cada marca y sus
            tiendas.
            <br />
            <br />
            De no existir stock, se procederá a la devolución parcial o total de
            la venta, según definición que el Usuario desee y corresponda.
            <br />
            <br />
            El despacho "express" de 2 a 6 hrs de realizada la compra está
            sujeta al horario de trabajo de la tienda para poder preparar y
            gestionar el pedido, como así también a la disponibilidad de
            operadores y al radio de entrega.
            <br />
            <br />
            Ahora bien, si recibes un producto en mal estado o un producto
            equivocado, deberás reportarlo directamente con la tienda que
            gestionó tu compra. Una vez recibida y procesada la devolución la
            tienda dará la instrucción para realizar la reversa del cargo en el
            medio de pago. Los reembolsos directo a la tarjeta pueden tardar
            entre 5 a 10 días hábiles en verse reflejados en tu cuenta.
            <br />
            <br />
            Las devoluciones directo en la tienda, están sujetas a las políticas
            de devolución de cada tienda y cada sucursal.
          </p>
        </div>
      </Container>
    </Layout>
  );
}
