import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import TextArea from "./TextArea";
import Input from "./Input";
import Select from "./Select";

const FormInput = ({
  type,
  placeholder,
  maxLength,
  autoComplete,
  width,
  maxWidth,
  margin,
  children,
  disabled,
  onKeyPress,
  onChange,
  field,
  form: { errors, touched },
}) => {
  return (
    <div>
      {type === "textarea" ? (
        <TextArea cols="30" rows="10" maxLength={maxLength} />
      ) : null}
      {type === "select" ? (
        <Select id={field.name} name={field.name}>
          {children}
        </Select>
      ) : null}
      {(type === undefined ||
        type === "text" ||
        type === "numeric" ||
        type === "password" ||
        type === "date") &&
      type !== "textarea" &&
      type !== "select" ? (
        <Input
          width={width}
          maxWidth={maxWidth}
          margin={margin}
          type={type}
          placeholder={placeholder}
          maxLength={maxLength}
          onKeyPress={onKeyPress}
          onChange={onChange}
          disabled={disabled}
          id={field.name}
          name={field.name}
          autoComplete={autoComplete}
          error={touched && touched[field.name] && errors && errors[field.name]}
          value={field.value}
        />
      ) : null}
      <ErrorText className="error-text">
        {touched && touched[field.name] && errors && errors[field.name]}
      </ErrorText>
    </div>
  );
};

const ErrorText = styled.p`
  margin-top: -10px;
`;

FormInput.propTypes = {
  type: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  autoComplete: PropTypes.string,
  width: PropTypes.string,
  maxWidth: PropTypes.string,
  margin: PropTypes.string,
  maxLength: PropTypes.number.isRequired,
  children: PropTypes.arrayOf(PropTypes.element),
  disabled: PropTypes.bool,
  onKeyPress: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  field: PropTypes.shape({ name: PropTypes.string, value: PropTypes.string })
    .isRequired,
  form: PropTypes.shape({
    errors: PropTypes.objectOf(PropTypes.string),
    touched: PropTypes.objectOf(PropTypes.bool),
  }).isRequired,
};

FormInput.defaultProps = {
  width: undefined,
  maxWidth: undefined,
  margin: undefined,
  children: undefined,
  disabled: false,
  onKeyPress: undefined,
  autoComplete: undefined,
};

export default FormInput;
