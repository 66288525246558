import * as types from "./types";

export function getLocalitiesInit() {
  return {
    type: types.GET_LOCALITIES_INIT,
  };
}

export function setUnregisterFlag() {
  return {
    type: types.SET_UNREGISTER_FLAG,
  };
}

export function getCategoriesInit(idBrand) {
  return {
    type: types.GET_CATEGORIES_INIT,
    idBrand
  };
}

export function setSelectedLocality(payload) {
  return {
    type: types.SET_SELECTED_LOCALITY,
    payload,
  };
}

export function openInfoModal(payload) {
  return {
    type: types.OPEN_INFO_MODAL,
    payload,
  };
}

export function openCartModal(payload) {
  return {
    type: types.OPEN_CART_MODAL,
    payload,
  };
}
export function closeCartModal(payload) {
  return {
    type: types.CLOSE_CART_MODAL,
    payload,
  };
}

export function closeInfoModal() {
  return {
    type: types.CLOSE_INFO_MODAL,
  };
}

export function showSnackbar(message, action, onClickAction) {
  return {
    type: types.SHOW_SNACKBAR,
    payload: message,
    action,
    onClickAction,
  };
}

export function closeSnackbar() {
  return {
    type: types.CLOSE_SNACKBAR,
  };
}

export function storeListInit() {
  return {
    type: types.GET_STORE_LIST_INIT,
  };
}

export function setIdBrandLogin(idBrand) {
  return {
    type: types.SET_ID_BRAND_LOGIN,
    idBrand,
  };
}
