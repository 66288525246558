export const primaryColor = "#000000";
export const secondaryColor = "#000000";
export const accentTextColor = "#000000";
export const lightTextColor = "#b1bbcd";

export const GOOGLE_MAPS_API_KEY = "AIzaSyA6NP5OW6Nqhc1bhq6H_TMNve_ye-DPZAU"; // "AIzaSyBYMpXjBKuzCJAeivAbJi4X1RenRQATZbU";

export const firebaseConfig = {
  apiKey: "AIzaSyA6NP5OW6Nqhc1bhq6H_TMNve_ye-DPZAU",
  authDomain: "now-fashion.firebaseapp.com",
  databaseURL: "https://now-fashion.firebaseio.com",
  projectId: "now-fashion",
  storageBucket: "now-fashion.appspot.com",
  messagingSenderId: "371589419746",
  appId: "1:371589419746:web:8c344458da410a5bf844bb",
  measurementId: "G-CQNTQ61J2N",
};

export const ORDERS_STATUS = {
  new: { text: "Pendiente", color: "#808080" },
  working: { text: "En preparación", color: "green" },
  paid: { text: "Pagada", color: primaryColor },
  cancelled: { text: "Cancelada", color: "#f44336" },
  ready: { text: "Listo para retiro", color: "green" },
  sent: { text: "En preparación", color: "green" },
  "En tránsito": { text: "En tránsito", color: "green" },
  "En preparación": { text: "En preparación", color: "green" },
  Entregado: { text: "Entregado", color: "green" },
  dispatched: { text: "En tránsito", color: "green" },
  payment_pending: { text: "Pago pendiente", color: "#ffcc00" },
  payment_rejected: { text: "Pago rechazado", color: "#f44336" },
};

const StoreDetailMenu = {
  img: true,
  first_title: "Outfits Exclusivos",
  second_title: "para ti",
  subtitle: "Búqueda avanzada",
  fields: [
    {
      title: "Sub categorías",
      type: "Select",
      id: "categorías",
      data: [],
    },
    {
      title: "Familias",
      type: "Select",
      data: [],
      id: "family",
    },
    {
      title: "Talla",
      type: "Select",
      data: [],
      id: "size",
    },
    {
      title: "Color",
      type: "Select",
      data: [],
      id: "color",
    },
    {
      title: "Precio",
      type: "Select",
      data: [],
      id: "price",
    },
    // {
    //   type: "radio",
    //   data: [{ id: "radio", values: "Si" }],
    // },
  ],
};

export const CATEGORIES = [
  {
    title: "MUJER",
    img: "../../assets/images/mujeres.jpg",
    subcategories: [
      {
        title: "Zapatos",
        families: [
          { title: "Botines" },
          { title: "Botas" },
          { title: "Zapatillas" },
        ],
      },
      {
        title: "Ropa",
        families: [
          { title: "Chaquetas" },
          { title: "Polerones" },
          { title: "Chaquetas y sweaters" },
        ],
      },
      {
        title: "Deportes",
        families: [
          { title: "Zapatillas outdoor" },
          { title: "Zapatillas Running" },
          { title: "Chaquetas" },
        ],
      },
    ],
  },
  {
    title: "HOMBRE",
    img: "../../assets/images/hombres.jpg",
    subcategories: [
      {
        title: "Zapatos",
        families: [
          { title: "Botines" },
          { title: "Botas" },
          { title: "Zapatillas" },
        ],
      },
      {
        title: "Ropa",
        families: [
          { title: "Chaquetas" },
          { title: "Polerones" },
          { title: "Chaquetas y sweaters" },
        ],
      },
      {
        title: "Deportes",
        families: [
          { title: "Zapatillas outdoor" },
          { title: "Zapatillas Running" },
          { title: "Chaquetas" },
        ],
      },
    ],
  },
  {
    title: "BEBÉS Y NIÑOS",
    img: "../../assets/images/ninios.jpg",
    subcategories: [
      {
        title: "Zapatos",
        families: [
          { title: "Botines" },
          { title: "Botas" },
          { title: "Zapatillas" },
        ],
      },
      {
        title: "Ropa",
        families: [
          { title: "Chaquetas" },
          { title: "Polerones" },
          { title: "Chaquetas y sweaters" },
        ],
      },
      {
        title: "Deportes",
        families: [
          { title: "Zapatillas outdoor" },
          { title: "Zapatillas Running" },
          { title: "Chaquetas" },
        ],
      },
    ],
  },
  {
    title: "DECOHOGAR",
    img: "../../assets/images/decogar.jpg",
    subcategories: [
      {
        title: "Zapatos",
        families: [
          { title: "Botines" },
          { title: "Botas" },
          { title: "Zapatillas" },
        ],
      },
      {
        title: "Ropa",
        families: [
          { title: "Chaquetas" },
          { title: "Polerones" },
          { title: "Chaquetas y sweaters" },
        ],
      },
      {
        title: "Deportes",
        families: [
          { title: "Zapatillas outdoor" },
          { title: "Zapatillas Running" },
          { title: "Chaquetas" },
        ],
      },
    ],
  },
  {
    title: "FOR SALE",
    img: "../../assets/images/forsale.jpg",
    subcategories: [
      {
        title: "Zapatos",
        families: [
          { title: "Botines" },
          { title: "Botas" },
          { title: "Zapatillas" },
        ],
      },
      {
        title: "Ropa",
        families: [
          { title: "Chaquetas" },
          { title: "Polerones" },
          { title: "Chaquetas y sweaters" },
        ],
      },
      {
        title: "Deportes",
        families: [
          { title: "Zapatillas outdoor" },
          { title: "Zapatillas Running" },
          { title: "Chaquetas" },
        ],
      },
    ],
  },
];

export const STORES = [
  {
    backgroundColor: "#05acec",
    img: "../../assets/images/Grupo-854.png",
    title: "Bershka",
  },
  {
    backgroundColor: "#ec8505",
    img: "../../assets/images/Grupo-855.png",
    title: "Converse",
  },
  {
    backgroundColor: "#f44336",
    img: "../../assets/images/Grupo-853.png",
    title: "Lacoste",
  },
  {
    backgroundColor: "#921953",
    img: "../../assets/images/Grupo-857.png",
    title: "Timberland",
  },
  {
    backgroundColor: "#0014ff",
    img: "../../assets/images/Grupo-852.png",
    title: "Tommy Hilfiger",
  },
  {
    backgroundColor: "#ff00c8",
    img: "../../assets/images/Grupo-854.png",
    title: "Bershka",
  },
  {
    backgroundColor: "#f44336",
    img: "../../assets/images/Grupo-857.png",
    title: "Timberland",
  },
];

const StoreListMenu = {
  img: true,
  first_title: "Descubre todas",
  second_title: "nuestras tiendas",
  subtitle: "Búqueda avanzada",
  fields: [
    {
      title: "Categoria ",
      type: "Select",
      id: "categoria",
      data: [],
    },
    {
      title: "Buscar por marca",
      type: "text",
      id: "search",
      data: [],
    },
  ],
};

const CheckoutFieldsContact = () => {
  const fields = [
    {
      title: "Nombre",
      id: "name",
      type: "text",
      width: "90%",
    },
    {
      title: "Apellido",
      id: "lastName",
      type: "text",
      width: "90%",
    },
    {
      title: "Correo electrónico",
      id: "email",
      type: "text",
      width: "90%",
    },
    {
      title: "Número de teléfono",
      id: "phone",
      type: "text",
      width: "90%",
      maxLength: 9,
    },
  ];
  return fields;
};

const CheckoutFieldsShippingInformation = () => {
  const fields = [
    {
      columns: [
        {
          columns: "0.5fr 0.5fr",
          fields: [
            {
              title: "Cuidad",
              id: "city",
              type: "text",
              width: "90%",
            },
            {
              title: "Zona",
              id: "zona",
              type: "text",
              width: "90%",
            },
          ],
        },
      ],
    },
    {
      columns: [
        {
          columns: "1fr",
          fields: [
            {
              title: "Calle o avenida",
              id: "address",
              type: "text",
              width: "95%",
            },
          ],
        },
      ],
    },
    {
      columns: [
        {
          columns: "0.5fr 0.5fr",
          fields: [
            {
              title: "N. de casa o edificio",
              id: "house",
              type: "text",
              width: "90%",
            },
            {
              title: "Codigo postal",
              id: "code",
              type: "text",
              width: "90%",
            },
          ],
        },
      ],
    },
    {
      columns: [
        {
          columns: "1fr",
          fields: [
            {
              title: "Instrucciones",
              id: "instructions",
              type: "text",
              width: "95%",
            },
          ],
        },
      ],
    },
  ];
  return fields;
};

const getMessage = () => {
  const message = [
    {
      approved: {
        colorTitle: "#b1bbcd",
        first_Title: "Tu compra se realizo",
        last_Title: "con exito",
        subTitle: "Pago Completado",
        colorSubtitle: "#3c434d",
        twoBtn: true,
        img: "../../assets/images/Grupo-883.png",
        gridColumns: "0.5fr 0.5fr",
        gridColumnsXs: "1fr",
        checkoutSuccess: true,
      },
    },
    {
      error: {
        colorTitle: "#b1bbcd",
        first_Title: "Ocurrio un error con",
        last_Title: "tu pago",
        subTitle: "Pago rechazado",
        colorSubtitle: "#ea4335",
        twoBtn: true,
        img: "../../assets/images/Grupo-907.png",
        gridColumns: "1fr",
        checkoutSuccess: false,
      },
    },
    {
      cancel: {
        colorTitle: "#b1bbcd",
        first_Title: "No termino el",
        last_Title: "proceso de pago",
        subTitle: "Pago cancelado",
        colorSubtitle: "#ea4335",
        twoBtn: true,
        img: "../../assets/images/Grupo-907.png",
        gridColumns: "1fr",
        checkoutSuccess: false,
      },
    },
    {
      payment_pending: {
        colorTitle: "#b1bbcd",
        first_Title: "Verificando su pago",
        last_Title: "ya que se encuentra",
        subTitle: "en revision",
        colorSubtitle: "#ffcc00",
        twoBtn: true,
        img: "../../assets/images/Grupo-1044.png",
        gridColumns: "1fr",
        checkoutSuccess: false,
      },
    },
    {
      payment_rejected: {
        colorTitle: "#b1bbcd",
        first_Title: "Su pago fue",
        last_Title: "rechazado",
        subTitle: "en estado rechazado",
        colorSubtitle: "#f44336",
        twoBtn: true,
        img: "../../assets/images/Grupo-907.png",
        gridColumns: "1fr",
        checkoutSuccess: false,
      },
    },
    {
      email: {
        colorTitle: "#b1bbcd",
        first_Title: "Tu correo se registro",
        last_Title: "con exito",
        subTitle: "Correo electrónico confirmado",
        colorSubtitle: "#3c434d",
        twoBtn: false,
        img: "../../assets/images/Grupo-897.png",
        gridColumns: "1fr",
        checkoutSuccess: false,
      },
    },
  ];
  return message;
};

const FieldsProfile = () => {
  const fields = [
    {
      id: "name",
      title: "Nombre",
      type: "text",
    },
    {
      id: "surname",
      title: "Apellido",
      type: "text",
    },
    {
      id: "email",
      title: "Correo electrónico",
      type: "text",
    },
    {
      id: "phone",
      title: "Número de teléfono",
      type: "text",
    },
    {
      id: "birthday",
      title: "Fecha de nacimiento",
      type: "date",
    },
    {
      id: "genero",
      title: "Genero",
      type: "select",
    },
  ];
  return fields;
};

const WarehouseOrder = {
  statuses: {
    requested: {value: "requested", label: "Sin atender"},
    processing: {value: "processing", label: "En proceso"},
    completed: {value: "completed", label: "Completada"},
    cancelled: {value: "cancelled", label: "Rechazada"},
    pending: {value: "pending", label: "Pendiente"}
}}

export const COLGRAM_BRAND_NAMES = ["colloky", 'opaline'];
export const COLGRAM_SCHOOL_CATEGORY_NAME = 'escolar';
export const COLGRAM_SHOES_CATEGORY_NAME = 'zapatos';
export {
  StoreListMenu,
  StoreDetailMenu,
  CheckoutFieldsContact,
  CheckoutFieldsShippingInformation,
  getMessage,
  FieldsProfile,
  WarehouseOrder
};
