import React, { useState, useEffect, /* useMemo, */ useRef } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import {
  MdMenu,
  MdKeyboardArrowDown,
  MdShoppingCart,
  MdPersonOutline,
  MdPersonAdd,
  MdStore,
  MdLockOutline,
  MdList,
  MdExitToApp,
  // MdLocationOn,
  MdKeyboardBackspace,
  MdContactPhone,
  MdSettings,
  MdUpload
} from "react-icons/md";

import {
  FaHouseUser
} from "react-icons/fa";

import {
 RiFileDownloadLine
} from "react-icons/ri";

import Popover, { ArrowContainer } from "react-tiny-popover";
import { useSelector, useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import Button from "../Button";
import FlatButton from "../FlatButton";
import { primaryColor } from "../../constants";
import {
  userSelector,
  fcmTokenSelector,
  tokenSelector,
} from "../../ducks/access/selectors";
import { logout } from "../../ducks/access/actions";
import { getLocalitiesInit, /* openCartModal */ } from "../../ducks/general/actions";
import {
  // selectedLocalitySelector,
  localitiesSelector,
} from "../../ducks/general/selectors";
import Drawer from "../Drawer";
import CartModal from "../CartModal";
// import { cartSelector } from "../../ducks/shopping/selectors";
import { getLocalityStoresInit } from "../../ducks/shopping/actions";
import { setShowManagerForm } from "../../ducks/store/actions";
import LocationModal from "../LocationModal";
import StoreManagerForm from "../StoreManagerForm";
import { ROLE_BRAND, ROLE_COMMERCE, /* ROLE_USER, */ someIncludes } from "../../utils";
import WarehouseCartModal from "../warehouseCartModal/WarehouseCartModal";
import useWarehouseCartStore from "../../hooks/useWarehouseCartStore";
import WarehouseCartPromotorModal from "../warehouseCartPromotorModal/WarehouseCartPromotorModal";
import { updateProductsLocations } from "../../services/api";
import WarehouseOrdersReport from "../reports/warehouseOrdersReportModal/WarehouseOrdersReportModal";
import useProductLocationUpdates from "../../hooks/useProductLocationUpdates";

const Container = styled.div`
  width: calc(100% - 60px);
  padding: 0px 20px 0px 40px;
  height: 70px;
  background-color: #ffffffeb;
  box-shadow: 0px 5px 5px 0px #80808080;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 1;
  @media (max-width: 420px) {
    height: 60px;
    width: calc(100% - 20px);
    padding: 0px 10px;
  }
`;

const Logo = styled.img`
  height: 70px;
  margin-top: 10px;
  cursor: pointer;
  @media (max-width: 420px) {
    height: 30px;
  }
`;

const BackButton = styled.div`
  height: 35px;
  width: 35px;
  box-shadow: 0px 0px 5px #80808080;
  border-radius: 20px;
  margin-right: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  transition-duration: 0.3s;
  cursor: pointer;
  &:hover {
    background-color: #80808080;
  }
`;

const RightContainer = styled.div`
  display: flex;
  margin-right: 20px;
  align-items: center;
  @media (max-width: 768px) {
    margin-right: 0px;
  }
`;

const Username = styled.p`
  font-weight: bold;
  color: #404040;
  margin-right: 3px;
  font-size: 14px;
  min-width: 120px;
  text-align: center;
  @media (max-width: 768px) {
    display: none;
  }
`;

const CartButton = styled.div`
  position: relative;
  background-color: #000;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
  border-radius: 25px;
  width: 50px;
  height: 50px;
  div.badge {
    position: absolute;
    right: -2px;
    top: -2px;
    width: 14px;
    height: 14px;
    background-color: #fff;
    border: 1px solid #000;
    color: #000;
    font-size: 12px;
    border-radius: 10px;
    padding: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  @media (max-width: 768px) {
    height: 36px;
    width: 36px;
    margin-right: 10px;
    div.badge {
      font-size: 10px;
      width: 12px;
      height: 12px;
      right: -4px;
      top: -3px;
    }
  }
`;

const UserMenuContainer = styled.div`
  background-color: #fafafa;
  box-shadow: 1px -1px 10px #80808080;
  margin: 0px 10px 10px 10px;
  padding: 16px;
  border-radius: 20px;
  width: 320px;
  p {
    font-weight: 500;
    font-size: 18px;
    color: #3c434d;
    margin-left: 10px;
    margin-bottom: 10px;
  }
  div.divider {
    height: 1px;
    background-color: #e0e0e0;
    margin-bottom: 10px;
  }
  @media (max-width: 420px) {
    width: 280px;
  }
`;

// const LocalityContainer = styled.div`
//   display: flex;
//   align-items: center;
//   padding-right: 20px;
//   div {
//     margin-right: 20px;
//   }
//   p.locality {
//     font-size: 14px;
//     color: #3c434d;
//     margin-bottom: -3px;
//   }
//   p {
//     font-size: 12px;
//     font-weight: 300;
//     color: #757a87;
//   }
//   img {
//     width: 20px;
//     height: 20px;
//   }
//   @media (max-width: 768px) {
//     display: none;
//   }
// `;

const DrawerContainer = styled.div`
  p {
    font-weight: bold;
    color: #404040;
    margin: 10px 20px 10px 20px;
    font-size: 14px;
  }
  div.divider {
    height: 1px;
    background-color: #eaeaea;
    margin: 10px 0px;
  }
`;

// const ComunaText = styled.p`
//   font-size: 13px;
//   @media (max-width: 768px) {
//     display: none;
//   }
// `;
const Header = ({ canGoBack }) => {
  const history = useHistory();
  const productLocationsForm = useRef(null)
  const dispatch = useDispatch();
  const user = useSelector(userSelector);
  const fcmToken = useSelector(fcmTokenSelector);
  const token = useSelector(tokenSelector);
  // const selectedLocality = useSelector(selectedLocalitySelector);
  const [userMenuOpen, setUserMenuOpen] = useState(false);
  const [openLocationModal, setOpenLocationModal] = useState(false);
  const [openResponsiveMenu, setOpenResponsiveMenu] = useState(false);
  const localities = useSelector(localitiesSelector);
  const initialLocalities = useRef(localities);
  const { warehouseCart, loadInitialData, toggleWarehouseCartModal } = useWarehouseCartStore()
  // const cart = useSelector(cartSelector);
  const [showWarehouseOrderReportModal, setShowWarehouseOrderReportModal] = useState(false)
  const { incrementProductLocationUpdates } = useProductLocationUpdates();

  useEffect(() => {
    if (!initialLocalities.current || initialLocalities.current.length === 0)
      dispatch(getLocalitiesInit());
  }, [dispatch]);

  useEffect(() => {
    loadInitialData(user?.commerce?.id)
  }, [])

  const handleLogout = () => {
    // Google Analytics
    if (process.env.REACT_APP_ENABLE_ANALYTICS_EVENTS === "true") {
      window.gtag('event', 'click', {
        'event_category': 'Header',
        'event_label': 'Cerrar sesion'
      });
    }

    dispatch(logout(fcmToken, token));
  };

  const handleChangeUpdateProductsLocationsInput = async (e) => {
    const formData = new FormData()
    const file = e?.currentTarget?.files?.[0]
    if (file) {
      formData.append('locations', file)
      const extension = file?.name?.split('.')?.[1]
      if (!['csv'].includes(extension)) {
        toast.error("Solo formato csv")
        return 
      }
      if (file.size > 20*1000*1000) {
        toast.error("Límite 20MB")
        return 
      }
      try {
        setUserMenuOpen(false)
        setOpenResponsiveMenu(false)
        toast.success('Actualizando...')
        productLocationsForm.current.reset()
        const res = await updateProductsLocations(user?.jobCommerce?.id, formData)
        incrementProductLocationUpdates()
        toast.success(res?.data?.message)
      } catch (error) {
        const msgError = error?.response?.data?.error
        const msgErrorMap = {file_required: "Archivo requerido", file_empty: "Archivo vacío", file_invalid: "Formato de archivo inválido"} 
        console.log("🚀 ~ file: Header.js:278 ~ handleChangeUpdateProductsLocationsInput ~ msgError:", msgError)
        toast.error(msgErrorMap?.[msgError] || "Ha ocurrido un error")
      }
    }
  }

  // const cartCount = useMemo(() => {
  //   if (!Array.isArray(cart)) return 0;
  //   let count = 0;
  //   cart.forEach((item) => {
  //     count += item.ordenItems.length;
  //   });
  //   return count;
  // }, [cart]);
  const handleClickStore = () => {
    // Google Analytics
    if (process.env.REACT_APP_ENABLE_ANALYTICS_EVENTS === "true") {
      window.gtag('event', 'click', {
        'event_category': 'Header',
        'event_label': 'Boton ir a /catalog'
      });
    }

    dispatch({ type: "SET_SELECTED_LOCALITY", payload: undefined });
    dispatch(getLocalityStoresInit());
    history.push("/catalog");
  };

  // const gtagCartModal = () => {
  //   // Google Analytics
  //   if (process.env.REACT_APP_ENABLE_ANALYTICS_EVENTS === "true") {
  //     window.gtag('event', 'click', {
  //       'event_category': 'Header',
  //       'event_label': 'Boton abrir Carrito Compra'
  //     });
  //   }
  // };

  return (
    <Container>
      <WarehouseOrdersReport isOpen={showWarehouseOrderReportModal} onClose={() => setShowWarehouseOrderReportModal(false)} />
      <div className="row-cont">
        {canGoBack && history.length > 2 ? (
          <BackButton onClick={history.goBack}>
            <MdKeyboardBackspace size={20} color="#000" />
          </BackButton>
        ) : null}
        <Link to={history.location.pathname !== "/" ? "/catalog" : "/"}>
          <Logo
            src="/assets/images/nike_1.svg"
            alt="now-fashion-logo"
            onClick={handleClickStore}
          />
        </Link>
      </div>
      <RightContainer>
        {/* {selectedLocality && !user.commerce ? (
          <div>
            <ComunaText>Comuna actual:</ComunaText>
            <LocalityContainer
              onClick={() => setOpenLocationModal(true)}
              className="opacity"
            >
              <div>
                <p className="locality">{selectedLocality.name}</p>
                <p>Cambiar</p>
              </div>
              <img src="/assets/images/arrow.svg" alt="arrow icon" />
            </LocalityContainer>
          </div>
        ) : (
          <LocalityContainer
            onClick={() => setOpenLocationModal(true)}
            className="opacity"
          >
            <div>
              <p className="locality">Sin comuna</p>
              <p>Cambiar</p>
            </div>
            <img src="/assets/images/arrow.svg" alt="arrow icon" />
          </LocalityContainer>
        )} */}

        {user && user.email ? (
          <div className="row-cont">
            <Popover
              style={{ zIndex: 999 }}
              isOpen={userMenuOpen}
              position={["bottom"]}
              padding={10}
              onClickOutside={() => setUserMenuOpen(false)}
              content={({ position, targetRect, popoverRect }) => (
                <ArrowContainer // if you'd like an arrow, you can import the ArrowContainer!
                  position={position}
                  targetRect={targetRect}
                  popoverRect={popoverRect}
                  arrowColor="#fafafa"
                  arrowSize={10}
                  arrowStyle={{ marginTop: 3 }}
                >
                  <UserMenuContainer
                    onClick={() => setUserMenuOpen(!userMenuOpen)}
                  >
                    {user && user.commerce ? (
                      <>
                        {/* <Link to="/store-home">
                          <p className="opacity">Mis pedidos</p>
                        </Link> */}
                        {someIncludes(user?.roles, [ROLE_COMMERCE]) && 
                          <Link to="/store/warehousers">
                            <p className="opacity">Mis bodegueros</p>
                          </Link>
                        }
                        {someIncludes(user?.roles, [ROLE_COMMERCE, ROLE_BRAND]) &&
                          <p className="opacity" onClick={() => {
                            setOpenResponsiveMenu(false)
                            setShowWarehouseOrderReportModal(true)
                          }}>Reporte bodegueros</p>
                        }
                        {
                          someIncludes(user?.roles, [ROLE_COMMERCE]) &&
                          <p
                            className="opacity"
                            onClick={() => dispatch(setShowManagerForm(true))}
                          >
                            Mis datos de contacto
                          </p>
                        }
                        {
                          someIncludes(user?.roles, [ROLE_COMMERCE]) &&
                          <Link to="/store-settings">
                            <p className="opacity">Configuración de tienda</p>
                          </Link>
                        }
                        <p
                          className="opacity"
                          onClick={() => history.push("/change-password")}
                        >
                          Cambiar contraseña
                        </p>
                      </>
                    ) : (
                      <>
                        {
                          user.roles.includes("ROLE_WAREHOUSE_WORKER") &&
                           <div>
                              <label htmlFor="file-upload">
                                  <p className="opacity">Actualizar ubiaciones</p>
                                  <form ref={productLocationsForm}>
                                  <input id="file-upload" className="hidden" type="file" onChange={handleChangeUpdateProductsLocationsInput}/>
                                  </form>
                              </label>
                          </div>
                        }
                        <p
                          className="opacity"
                          onClick={() => history.push("/purchase-history/1")}
                        >
                          Historial de compra
                        </p>
                        <p
                          className="opacity"
                          onClick={() =>
                            history.push("/profile", { query: "profile" })
                          }
                        >
                          Editar perfil
                        </p>

                        <p
                          className="opacity"
                          onClick={() => history.push("/change-password")}
                        >
                          Cambiar contraseña
                        </p>
                      </>
                    )}

                    <div className="divider" />

                    <p onClick={handleLogout} className="opacity">
                      Cerrar sesión
                    </p>
                  </UserMenuContainer>
                </ArrowContainer>
              )}
            >
              {(
                ref // if you'd like access to the ref itself for some reason, you can provide a function as a child into which the ref will be injected
              ) => (
                <div
                  role="button"
                  tabIndex="-1"
                  ref={ref}
                  onClick={() => setUserMenuOpen(true)}
                  className="row-cont opacity hidden-sm"
                >
                  <Username>
                    {user?.name && `${user.name} ${user.surname}`}
                    {user?.brand?.name || user?.commerce?.name}
                  </Username>
                  <MdKeyboardArrowDown size={20} color="#404040" />
                </div>
              )}
            </Popover>
          </div>
        ) : null}
        {window.location.pathname === "/" && (!user || !user.email) ? (
          <>
            <Link to="/login" className="hidden-sm">
              <FlatButton>Iniciar sesion</FlatButton>
            </Link>
            <Link to="/register" className="hidden-sm">
              <Button margin="0px 10px">Regístrate</Button>
            </Link>
          </>
        ) : null}
        {window.location.pathname !== "/" && (!user || !user.email) ? (
          <Link to="/login" className="hidden-sm">
            <Username>Mi cuenta</Username>
          </Link>
        ) : null}
        {/* {(!user?.id || (user?.roles?.includes(ROLE_USER) && user.roles.length === 1)) && (
          <CartButton
            onClick={() => { gtagCartModal(); dispatch(openCartModal(true)); }}
            className="opacity"
          >
            <MdShoppingCart color="#fff" size={20} />
            {cartCount ? <div className="badge">{cartCount}</div> : null}
          </CartButton>
        )} */}
        {user?.roles?.includes(ROLE_COMMERCE) &&
          <CartButton
            onClick={toggleWarehouseCartModal}
            className="opacity"
          >
            <MdShoppingCart color="#fff" size={20} />
            {!!warehouseCart.items.length && <div className="badge">{warehouseCart.items.reduce((acc, item) => acc + item.quantity, 0)}</div>}
          </CartButton>
        }

        <MdMenu
          onClick={() => setOpenResponsiveMenu(true)}
          className="opacity hidden-md-and-up"
          size={26}
          color={primaryColor}
        />
      </RightContainer>
      <CartModal />
      <WarehouseCartModal />
      <WarehouseCartPromotorModal />

      <Drawer
        isOpen={openResponsiveMenu}
        onClose={() => setOpenResponsiveMenu(false)}
      >
        <DrawerContainer>
          {user && user.email ? (
            <>
              {user.commerce ? (
                <>
                  {/* <div className="row-cont">
                    <MdList size={26} color={primaryColor} />

                    <p
                      className="opacity"
                      onClick={() => history.push("/store-home")}
                    >
                      Mis pedidos
                    </p>
                  </div> */}
                  { someIncludes(user?.roles, [ROLE_COMMERCE]) &&
                    <div className="row-cont">
                      <FaHouseUser size={26} color={primaryColor} stroke={primaryColor} />
                      <Link to="/store/warehousers">
                        <p className="opacity">Mis bodegueros</p>
                      </Link>
                    </div>
                  }
                  {
                    someIncludes(user?.roles, [ROLE_COMMERCE, ROLE_BRAND]) &&
                    <div className="row-cont">
                      <RiFileDownloadLine size={26} color={primaryColor} stroke={primaryColor} />
                      <p className="opacity" onClick={() => {
                        setOpenResponsiveMenu(false)
                        setShowWarehouseOrderReportModal(true)
                      }}>Reporte bodegueros</p>
                    </div>
                  }
                  {
                    someIncludes(user?.roles, [ROLE_COMMERCE]) &&
                    <div
                      role="button"
                      tabIndex="-1"
                      onClick={() => dispatch(setShowManagerForm(true))}
                      className="row-cont"
                    >
                      <MdContactPhone size={26} color={primaryColor} />
                      <p className="opacity">Mis datos de contacto</p>
                    </div>
                  }
                  {
                    someIncludes(user?.roles, [ROLE_COMMERCE]) &&
                    <Link to="/store-settings">
                      <div className="row-cont">
                        <MdSettings size={26} color={primaryColor} />

                        <p className="opacity">Configuración de tienda</p>
                      </div>
                    </Link>
                  }
                  <div className="row-cont">
                    <MdLockOutline size={26} color={primaryColor} />
                    <p
                      className="opacity"
                      onClick={() => history.push("/change-password")}
                    >
                      Cambiar contraseña
                    </p>
                  </div>
                </>
              ) : (
                <>
                  {
                    user.roles.includes("ROLE_WAREHOUSE_WORKER") &&
                      <div className="row-cont">
                        <MdUpload size={26} color={primaryColor} />
                        <label htmlFor="file-upload">
                            <p className="opacity">Actualizar ubiaciones</p>
                            <form ref={productLocationsForm}>
                            <input id="file-upload" className="hidden" type="file" onChange={handleChangeUpdateProductsLocationsInput}/>
                            </form>
                        </label>
                    </div>
                  }
                  <div className="row-cont">
                    <MdPersonOutline size={26} color={primaryColor} />
                    <p
                      className="opacity"
                      onClick={() =>
                        history.push("/profile", { query: "profile" })
                      }
                    >
                      Editar perfil
                    </p>
                  </div>
                  <div className="row-cont">
                    <MdLockOutline size={26} color={primaryColor} />
                    <p
                      className="opacity"
                      onClick={() =>
                        history.push("/profile", { query: "password" })
                      }
                    >
                      Cambiar contraseña
                    </p>
                  </div>
                  { user && user?.roles?.length === 1 && user?.roles?.[0] === "ROLE_USER" &&
                    <div className="row-cont">
                    <MdList size={26} color={primaryColor} />

                    <p
                      className="opacity"
                      onClick={() => history.push("/purchase-history/1")}
                    >
                      Historial de compra
                    </p>
                  </div>
                  }
                  {/* <div className="row-cont">
                    <MdLocationOn size={26} color={primaryColor} />
                    <p
                      className="opacity"
                      onClick={() => {
                        setOpenResponsiveMenu(false);
                        setOpenLocationModal(true);
                      }}
                    >
                      Cambiar comuna
                    </p>
                  </div> */}
                </>
              )}

              <div className="divider" />
              <div className="row-cont">
                <MdExitToApp size={26} color={primaryColor} />
                <p onClick={handleLogout} className="opacity">
                  Cerrar sesión
                </p>
              </div>
            </>
          ) : (
            <>
              <div className="row-cont">
                <MdPersonOutline size={26} color={primaryColor} />
                <p className="opacity" onClick={() => history.push("/login")}>
                  Iniciar sesion
                </p>
              </div>
              <div className="row-cont">
                <MdPersonAdd size={26} color={primaryColor} />
                <p
                  className="opacity"
                  onClick={() => history.push("/register")}
                >
                  Registro
                </p>
              </div>
              <div className="row-cont">
                <MdStore size={26} color={primaryColor} />
                <p className="opacity">Tiendas</p>
              </div>
              {/* <div className="row-cont">
                <MdLocationOn size={26} color={primaryColor} />
                <p
                  className="opacity"
                  onClick={() => {
                    setOpenResponsiveMenu(false);
                    setOpenLocationModal(true);
                  }}
                >
                  Cambiar comuna
                </p>
              </div> */}
            </>
          )}
        </DrawerContainer>
      </Drawer>
      {user &&
        user.commerce &&
        user.roles.findIndex((item) => item === "ROLE_BRAND") === -1 ? (
        <StoreManagerForm />
      ) : null}
      <LocationModal
        open={openLocationModal}
        onClose={() => setOpenLocationModal(false)}
      />
    </Container>
  );
};

export default Header;

Header.propTypes = {
  canGoBack: PropTypes.bool.isRequired,
};
