import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const StyledTextArea = styled.textarea`
  width: ${(props) => props.width};
  margin: ${(props) => props.margin};
  padding: 10px 10px;
  height: ${(props) => props.height || "80px"};
  border: 1px solid #c0c0c0;
  border-radius: 8px;
  outline: none;
  font-family: "Poppins", sans-serif;
  color: #4f4f4f;
  resize: none;
`;

function TextArea({
  placeholder,
  onChange,
  onKeyPress,
  value,
  type,
  width,
  margin,
  id
}) {
  return (
    <StyledTextArea
      placeholder={placeholder}
      onChange={onChange}
      onKeyPress={onKeyPress}
      value={value}
      type={type}
      width={width}
      margin={margin}
      id={id}
    />
  );
}

TextArea.propTypes = {
  placeholder: PropTypes.string,
  width: PropTypes.string,
  margin: PropTypes.string,
  value: PropTypes.string,
  type: PropTypes.string,
  onChange: PropTypes.func,
  onKeyPress: PropTypes.func,
  id: PropTypes.string.isRequired
};

TextArea.defaultProps = {
  placeholder: "",
  width: "100%",
  margin: undefined,
  value: undefined,
  type: "text",
  onChange: undefined,
  onKeyPress: undefined,
};

export default TextArea;
