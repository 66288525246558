import React, { useState } from 'react'
import { toast } from 'react-toastify'
import { useSelector } from 'react-redux'
import ModalBase from '../../modal/ModalBase/ModalBase'
import BasicDatePicker from '../../BaseDatePicker'
import Button from '../../Button'
import AbsoluteLoader from '../../AbsoluteLoader'
import { userSelector } from '../../../ducks/access/selectors'
import { getTokenResource } from '../../../services/api'

const WarehouseOrdersReportModal = ({isOpen, onClose}) => {
  const [dates, setDates] = useState({start: null, end: null})
  const user = useSelector(userSelector)
  const [isLoading, setIsLoading] = useState(false)
  
  const handleDownloadReport = async () => {
    const baseUrl = process.env.REACT_APP_BASE_URL
    const start = dates?.start?.format('YYYY-MM-DD') || ''
    const end = dates?.end?.format('YYYY-MM-DD') || ''
    try {
      setIsLoading(true)
      const res = await getTokenResource({userId: user?.id})
      const url = `${baseUrl}/api/reports/warehouses/orders/${res?.data?.token}?dateStart=${start}&dateEnd=${end}`
      window.open(url, '_blank')
      onClose()
    } catch (error) {
      toast.error("Ha ocurrido un error")
      console.log("🚀 ~ file: WarehouseOrdersReportModal.js:24 ~ handleDownloadReport ~ error:", error)
    }finally{
      setIsLoading(false)
    }
  }

  return (
    <ModalBase isOpen={isOpen} onClose={onClose}>
      <AbsoluteLoader visible={isLoading} />
      <div className="flex flex-col mb-5"> 
        Desde
        <BasicDatePicker onChange={(e) => setDates({...dates, start: e})} />
      </div>
      <div className="flex flex-col mb-5">
        Hasta
        <BasicDatePicker onChange={(e) => setDates({...dates, end: e})} />
      </div>
      <div className='flex justify-end'>
        <Button type='secondary' className='text-right' onClick={handleDownloadReport}>
          Descargar
        </Button>
      </div>
    </ModalBase>
  )
}

export default WarehouseOrdersReportModal
