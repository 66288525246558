export const GET_LOCALITIES_INIT = "GET_LOCALITIES_INIT";
export const GET_LOCALITIES_COMPLETE = "GET_LOCALITIES_COMPLETE";
export const GET_LOCALITIES_ERROR = "GET_LOCALITIES_ERROR";

export const GET_CATEGORIES_INIT = "GET_CATEGORIES_INIT";
export const GET_CATEGORIES_COMPLETE = "GET_CATEGORIES_COMPLETE";
export const GET_CATEGORIES_ERROR = "GET_CATEGORIES_ERROR";

export const GET_STORE_LIST_INIT = "GET_STORE_LIST_INIT";
export const GET_STORE_LIST_COMPLETE = "GET_STORE_LIST_COMPLETE";
export const GET_STORE_LIST_ERROR = "GET_STORE_LIST_ERROR";

export const SET_SELECTED_LOCALITY = "SET_SELECTED_LOCALITY";

export const OPEN_INFO_MODAL = "OPEN_INFO_MODAL";
export const CLOSE_INFO_MODAL = "CLOSE_INFO_MODAL";

export const SHOW_SNACKBAR = "SHOW_SNACKBAR";
export const CLOSE_SNACKBAR = "CLOSE_SNACKBAR";

export const OPEN_CART_MODAL = "OPEN_CART_MODAL";
export const CLOSE_CART_MODAL = "CLOSE_CART_MODAL";

export const SET_UNREGISTER_FLAG = "SET_UNREGISTER_FLAG";

export const SET_ID_BRAND_LOGIN = "SET_ID_BRAND_LOGIN";