import styled from "styled-components";
import Button from "./Button";

const FlatButton = styled(Button)`
  background-color: transparent;
  background-image: none;
  color: #404040;
  &:hover {
    background-color: #80808080;
  }
`;

export default FlatButton;
