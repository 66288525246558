import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { MdCancel } from 'react-icons/md';
import styled from 'styled-components';
import Modal from 'react-modal';
// import GooglePlacesAutocomplete, {
//   getLatLng,
//   geocodeByPlaceId,
// } from "react-google-places-autocomplete";
import { useSelector, useDispatch } from 'react-redux';
import MyMap from './MyMap';
import { localitiesSelector, selectedLocalitySelector } from '../ducks/general/selectors';
import Select from './Select';
// import Input from "./Input";
import Button from './Button';
import { setSelectedLocality } from '../ducks/general/actions';
import 'react-google-places-autocomplete/dist/index.min.css';
import { userSelector } from '../ducks/access/selectors';
import { updateProfileInit } from '../ducks/access/actions';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    padding: 0,
    marginRight: '-50%',
    borderRadius: 20,
    boxShadow: '0 20px 20px 0 rgba(0, 0, 0, 0.1)',
    border: 'none',
    transform: 'translate(-50%, -50%)',
    overflow: 'visible',
  },
};

const Container = styled.div`
  padding: 0px;
  padding-bottom: 20px;
  position: relative;
  width: calc(100vw - 20px);
  max-width: 600px;
  min-height: 600px;
  display: flex;
  flex-direction: column;
  @media (max-width: 420px) {
    padding-bottom: 20px;
    min-height: 80vh;
  }
`;

const Content = styled.div`
  flex: 1;
  padding: 16px;
`;

const CloseButton = styled.div`
  background-color: #fff;

  border-radius: 13px;
  display: flex;
  justify-content: flex-end;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-right: 5px;
`;

const FormRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
  margin-top: 20px;
  @media (max-width: 768px) {
    display: block;
    select {
      width: 100%;
      margin: 10px 0px;
    }
  }
`;

Modal.setAppElement('#root');

export default function LocationModal({ open, onClose }) {
  const dispatch = useDispatch();
  // const [mapsScriptLoaded, setMapsScriptLoaded] = useState(false);
  const localities = useSelector(localitiesSelector);
  const initialSelectedLocality = useSelector(selectedLocalitySelector);
  const user = useSelector(userSelector);
  const [selectedLocality, editSelectedLocality] = useState(
    initialSelectedLocality && initialSelectedLocality.value
  );
  const [selectedLocalityName, setSelectedLocalityName] = useState( // GTM
    initialSelectedLocality && initialSelectedLocality.name
  );
  const [selectedRegion, setSelectedRegion] = useState(
    initialSelectedLocality && initialSelectedLocality.regionId
  );
  const [selectedRegionName, setSelectedRegionName] = useState( // GTM
    initialSelectedLocality && initialSelectedLocality.regionName
  );
  const [selectedPlace] = useState(
    user && user.address
      ? {
          address: user.address,
          coords: user.latitude
            ? {
                lat: parseFloat(user.latitude),
                lng: parseFloat(user.longitude),
              }
            : null,
        }
      : null
  );
  const mapRef = useRef();

  const handleSave = () => {
        // Google Analytics
        if(process.env.REACT_APP_ENABLE_ANALYTICS_EVENTS==="true") {
        window.gtag('event', 'click', {
          'event_category': 'Header',
          'event_label': 'Boton Guardar comuna en Modal'
        });
      }
    
    const locality = {};
    localities.forEach((region) => {
      region.items.forEach((item) => {
        if (item.value === parseInt(selectedLocality, 10)) {
          locality.value = item.value;
          locality.name = item.name;
          locality.regionId = region.id;
          locality.regionName = region.name;
        }
      });
    });
    dispatch(setSelectedLocality(locality));
    if (selectedPlace && selectedPlace.address && user && user.id)
      dispatch(
        updateProfileInit(user.id, {
          ...user,
          address: selectedPlace.address,
          latitude:
            selectedPlace.coords && selectedPlace.coords.lat && selectedPlace.coords.lat.toString(),
          longitude:
            selectedPlace.coords && selectedPlace.coords.lng && selectedPlace.coords.lng.toString(),
        })
      );
    if (onClose) {
      onClose();
    }
  };

  // const handleSelectPlace = (placeResult) => {
  //   geocodeByPlaceId(placeResult.place_id)
  //     .then((places) => getLatLng(places[0]))
  //     .then((coords) => {
  //       setSelectedPlace({
  //         address: placeResult.description,
  //         coords,
  //       });
  //       mapRef.current.setZoom(17);
  //     })
  //     .catch((e) => {
  //       console.log(e);
  //     });
  // };

  const handleSelectLocality = ({ target: { value } }) => {
     // Google Analytics
     if(process.env.REACT_APP_ENABLE_ANALYTICS_EVENTS==="true") {
     window.gtag('event', 'click', {
      'event_category': 'Header',
      'event_label': 'Seleccion en campo de Busqueda de comuna Map'
    });
  }

    if (mapRef && mapRef.current) {
      let foundLocality;
      localities.forEach((region) => {
        region.items.forEach((commune) => {
          if (commune.value === parseInt(value, 10)) {
            foundLocality = commune;
          }
        });
      });
      if (foundLocality) {

        setSelectedLocalityName(foundLocality.name); // GTM

        const placesService = new window.google.maps.places.PlacesService(mapRef.current);
        const request = {
          query: `${foundLocality.name}`,
          fields: ['name', 'geometry'],
        };
        placesService.findPlaceFromQuery(request, (results, status) => {
          if (
            status &&
            window.google &&
            status === window.google.maps.places.PlacesServiceStatus.OK
          ) {
            mapRef.current.setCenter(results[0].geometry.location);
            mapRef.current.setZoom(12);
          }
        });
      }
    }
    editSelectedLocality(value);
  };

  useEffect(() => {
    if (initialSelectedLocality) {
      editSelectedLocality(initialSelectedLocality.value);
      setSelectedRegion(initialSelectedLocality.regionId);
    }
  }, [initialSelectedLocality]);

  return (
    <Modal
      isOpen={open}
      onRequestClose={onClose}
      style={customStyles}
      closeTimeoutMS={300}
      contentLabel='Detalle del producto'
    >
      <Container>
        <CloseButton>
          <MdCancel onClick={onClose} className='opacity' size={22} color='#b1bbcd' />
        </CloseButton>
        <MyMap
          coordsTitle={selectedPlace && selectedPlace.address}
          coords={selectedPlace && selectedPlace.coords}
          // onLoadScript={() => setMapsScriptLoaded(true)}
          onLoadMap={(map) => {
            // setMapsScriptLoaded(true);
            mapRef.current = map;
            if ((!selectedPlace || !selectedPlace.coords) && initialSelectedLocality) {
              const placesService = new window.google.maps.places.PlacesService(map);
              const request = {
                query: `${initialSelectedLocality.name}`,
                fields: ['name', 'geometry'],
              };
              placesService.findPlaceFromQuery(request, (results, status) => {
                if (window.google && status === window.google.maps.places.PlacesServiceStatus.OK) {
                  map.setCenter(results[0].geometry.location);
                  map.setZoom(12);
                }
              });
            }
          }}
          // onUnmountScript={() => setMapsScriptLoaded(false)}
        />

        <Content>
          <FormRow>
            <Select
            title={selectedRegionName}
              value={selectedRegion}
              onChange={({ target: { value } }) => { 
              setSelectedRegion(value);
               // Google Analytics
               if(process.env.REACT_APP_ENABLE_ANALYTICS_EVENTS==="true") {
               window.gtag('event', 'click', {
                'event_category': 'Header',
                'event_label': 'Seleccion en campo de Busqueda de region Map'
              });
            }
            // GTM
            let foundRegion;
      localities.forEach((region) => {
          if (region.id === parseInt(value, 10)) {
            foundRegion = region;
          }
      });
      if (foundRegion) {
        setSelectedRegionName(foundRegion.name);
       }

              }}
              padding='0px 25px 0px 10px'
              width='100%'
            >
              {/* <option>Seleccionar Comuna</option> */}
              <option>Seleccionar region</option>
              {Array.isArray(localities)
                ? localities.map((region) => (
                    <option value={region.id} key={region.id}>
                      {region.name}
                    </option>
                  ))
                : null}
            </Select>
            <Select title={selectedLocalityName} value={selectedLocality} onChange={handleSelectLocality} width='100%'>
              {/* <option>Seleccionar Comuna</option> */}
              <option>Seleccionar comuna</option>
              {Array.isArray(localities)
                ? localities.map((region) => {
                    return region.id === parseInt(selectedRegion, 10) && Array.isArray(region.items)
                      ? region.items
                          .sort((a, b) => {
                            if (a.name > b.name) {
                              return 1;
                            }
                            if (a.name < b.name) {
                              return -1;
                            }
                            return 0;
                          })
                          .map((commune) => (
                            <option value={commune.value} key={commune.value}>
                              {commune.name}
                            </option>
                          ))
                      : null;
                  })
                : null}
            </Select>
          </FormRow>
          {/* open && mapsScriptLoaded? (
            <GooglePlacesAutocomplete
              initialValue={user && user.address}
              placeholder="Dirección para envíos"
              autocompletionRequest={{
                componentRestrictions: {
                  country: ["cl"],
                },
                types: ["address"],
              }}
              renderInput={({ placeholder, onChange, value }) => (
                <Input
                  placeholder={placeholder}
                  onChange={onChange}
                  value={value}
                  margin="20px 0px"
                  width="calc(100% - 22px)"
                />
              )}
              onSelect={handleSelectPlace}
            />
          ) : null */}
        </Content>
        <div className='total-center'>
          <Button onClick={handleSave}>Guardar</Button>
        </div>
      </Container>
    </Modal>
  );
}

LocationModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};
