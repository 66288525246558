export const storeOrdersSelector = (state) => state.storeStore.storeOrders;
export const isGettingStoreOrdersSelector = (state) =>
  state.storeStore.isGettingStoreOrders;
export const currentStoreOrderSelector = (state) =>
  state.storeStore.currentStoreOrder;
export const isUpdatingStoreManagerSelector = (state) =>
  state.storeStore.isUpdatingStoreManager;
export const showManagerFormSelector = (state) =>
  state.storeStore.showManagerForm;
export const isUpdatingStoreOrderSelector = (state) =>
  state.storeStore.isUpdatingStoreOrder;

export const isUpdatingStoreSelector = (state) =>
  state.storeStore.isUpdatingStore;
export const storeVariantsSelector = (state) => state.storeStore.storeVariants;
export const storeManifestSelector = (state) => state.storeStore.manifest;

export const isLoadingMatrixProductsSelector = (state) => state.storeStore.isLoadingMatrixProducts;
export const matrixProductsSelector = (state) => state.storeStore.matrixProducts;
export const errorMatrixProductsSelector = (state) => state.storeStore.errorMatrixProducts;
export const totalPagesProductsSelector = (state) => state.storeStore.totalPagesProducts;





export const loadStocksByVariantSelector = (state) => state.storeStore.loadStocksByVariant;
export const stocksByVariantSelector = (state) => state.storeStore.stocksByVariant;
export const errorStocksByVariantSelector = (state) => state.storeStore.errorStocksByVariant;

export const iVariant1GralSelector = (state) => state.storeStore.iVariant1Gral;
export const iVariant2GralSelector = (state) => state.storeStore.iVariant2Gral;

export const isLoadingStoreSubCategorySelector = (state) => state.storeStore.isLoadingStoreSubCategory;
export const storeSubCategorySelector = (state) => state.storeStore.storeSubCategory;

export const loadTemporadasSelector = (state) => state.storeStore.loadTemporadas;
export const temporadasSelector = (state) => state.storeStore.temporadas;
export const errorTemporadasSelector = (state) => state.storeStore.errorTemporadas;

export const filtersGralSelector = (state) => state.storeStore.filtersGral;

export const fieldSortSelector = (state) => state.storeStore.fieldSort;