import React, { useState } from 'react';
import { Form, Formik } from 'formik';
import { object, string } from 'yup';
import { toast } from 'react-toastify';
import styles from './styles.module.css';
import ModalBase from '../modal/ModalBase/ModalBase';
import AbsoluteLoader from '../AbsoluteLoader';
import Input from '../formik/Input';
import Button from '../Button';
import useWarehouseCartStore from '../../hooks/useWarehouseCartStore';
import { requestWarehouserOrder } from '../../services/api';

function WarehouseCartPromotorModal() {
    const [isLoading, setIsLoading] = useState(false);
    const {
        warehouseCart,
        clearWarehouseCart,
        toggleWarehouseCartPromotorModal
    } = useWarehouseCartStore()

    const validationSchema = 
        object({
            name: 
                string()
                .max(255, 'Máximo 255 caracteres')
                .required('Requerido')
            })
    
    const initialValues = {
        name: '',
    }
    
    return (
        <ModalBase isOpen={warehouseCart.isOpenModalPromotor} onClose={toggleWarehouseCartPromotorModal} modalClassName={styles.modal}>
            <AbsoluteLoader visible={isLoading}>
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={async (values) => {
                        try {
                            setIsLoading(true)
                            await requestWarehouserOrder(
                                warehouseCart.commerceId, 
                                { 
                                    items: warehouseCart.items,
                                    requestedBy: values.name
                                 })
                            clearWarehouseCart()
                            toggleWarehouseCartPromotorModal()
                            toast.success("Solicitado con éxito")
                        } catch (error) {
                            toast.error("Ha ocurrido un error")
                        }finally{
                            setIsLoading(false)
                        }
                    }}
                >
                    <Form>
                        <Input type="tel" name="name" id="name" label="Código de vendedor" containerClassName="mb-4" justNumbers />
                        <div className='flex justify-between'>
                            <Button type='button' backgroundColor="#f44336" onClick={toggleWarehouseCartPromotorModal}>
                                Cancelar
                            </Button>
                            <Button type='submit'>
                                Solicitar
                            </Button>
                        </div>
                    </Form>
                </Formik>
            </AbsoluteLoader>
        </ModalBase>
    );
}

export default WarehouseCartPromotorModal;
