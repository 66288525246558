import styled from "styled-components";
import PropTypes from "prop-types";
import { primaryColor } from "../constants";

const Button = styled.button`
  background-color: ${(props) =>
    props.outline || props.backgroundColor
      ? props.backgroundColor || "transparent"
      : primaryColor};
  background-image: ${(props) =>
    props.outline || props.backgroundColor
      ? "none"
      : `linear-gradient(91deg, ${primaryColor} 23%, ${primaryColor} 82%)`};

  height: ${(props) => props.height || "35px"};
  width: ${(props) => props.width};
  max-width: ${(props) => props.maxWidth};
  border-radius: 20px;
  user-select: none;
  text-align: center;
  color: ${(props) =>
    props.outline || props.color ? props.color || primaryColor : "#fff"};
  outline: none;
  padding: 0px 20px;
  font-family: "Poppins";
  border: ${(props) =>
    props.outline || props.border
      ? `1px solid ${props.border || primaryColor}`
      : "none"};
  font-size: ${(props) => props.fontSize || "14px"};
  margin: ${(props) => props.margin};
  transition-duration: 0.3s;
  cursor: pointer;
  font-weight: 300;
  &:hover {
    background-color: ${(props) =>
      props.outline || props.backgroundColor
      ? props.backgroundColor || "transparent"
      : primaryColor};
    background-image: none;
    opacity: ${(props) => (props.outline ? 1.0 : 0.5)};
    color: #fff;
  }
  @media (max-width: 998px) {
    font-size: 12px;
  }
  @media (max-width: 768px) {
    width: ${(props) => props.widthSm};
    padding: ${(props) => props.paddingSm};
    margin: ${(props) => props.marginSm};
  }
`;

Button.propTypes = {
  outline: PropTypes.bool,
};

export default Button;
