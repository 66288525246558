import * as React from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import "dayjs/locale/es";
import "dayjs/locale/en";
import { ThemeProvider, createTheme } from '@mui/material/styles'
import { esES } from '@mui/x-date-pickers/locales';
import styled from 'styled-components';

const newTheme = createTheme({
  palette: {
    primary: {
      main: '#12cad6'
    }
  },
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        input: {
          borderRadius: "8px",
          height: "5px",
          border: '1px solid none'
        }
      }
    },
    MuiDateCalendar: {
      styleOverrides: {
        root: {
          borderRadius: 2,
          border: '1px solid #12cad6',
        }
      }
    }
  }
})

const BasicDatePicker = ({onChange=()=>{}, value}) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es-ES" localeText={esES.components.MuiLocalizationProvider.defaultProps.localeText}>
      <ThemeProvider theme={newTheme}>
        <DatePicker  closeOnSelect value={value} onChange={onChange} format='DD-MM-YYYY' slotProps={{ actionBar: { actions: ['clear']}, popper: { placement: 'auto', strategy: 'fixed'}}}/>
      </ThemeProvider>
    </LocalizationProvider>
  );
}

const StyledBasicDatePicker = styled(BasicDatePicker)({
  '.MuiDateCalendar-root': {
    color: 'red',
    borderRadius: 2,
    borderWidth: 1,
    borderColor: '#2196f3',
    border: '1px solid',
    backgroundColor: '#0d47a1',
  }
})

export default StyledBasicDatePicker;