import API from "../api";

export function getWarehouses(storeId, params) {
    return API.get(`/api/commerces/${storeId}/warehouse-workers`, { params })
}

export function updateWarehouser(id, data) {
    return API.put(`/api/warehousers/${id}`, { ...data })
}

export function createWarehouser(data) {
    return API.post(`/api/warehousers`, { ...data })
} 

export function requestWarehouserOrder(commerceId, data) {
    return API.post(`/api/commerces/${commerceId}/warehouse-orders`, { ...data })
}

export function getWarehouserOrderById(id) {
    return API.post(`/api/warehouse/orders/${id}`)
}

export function getStoresWithStock(params) {
    return API.get(`/api/stores-with-stock`, { params })
}

export function updateProductsLocations(commerceId, formData) {
    return API.post(`/api/commerces/${commerceId}/products-locations`, formData, {headers: { 'Content-Type': 'multipart/form-data' }})
}

export function getTokenResource(params) {
    return API.post(`/api/users/${params.userId}/tokens/resources`)
}